<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add a Candidate
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete outlined ref="rank" v-model="form.rank" :items="ranks" label="Rank *"
                append-icon="mdi-account" :error-messages="errors.rank"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Given Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.last_name" outlined label="Surname Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.last_name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.phone" outlined label="Mobile no *" prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.email" outlined label="Email *" prepend-inner-icon="mdi-email"
                :error-messages="errors.email"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card-text>
              <v-divider class="mt-5 mb-5"> </v-divider>
              <v-progress-circular style="float: right;" v-if="isLoading" indeterminate
                color="primary"></v-progress-circular>
              <v-btn style="float: right;" v-else color="primary" dark @click="save"> Save Candidate </v-btn>
            </v-card-text>

          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        active: true,
      },
      companies: [],
      dob_menu: false,
      roles: [
        {
          id: 0,
          text: "Select Role",
          value: "",
        },
      ],
      ranks: [],
      teachers: [],
      nationalities: [],
      birth_places: [],
      courses: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;
      // Roles
      masters.ranks.forEach((rank) => {
        this.ranks.push({
          id: rank.id,
          text: rank.description,
          value: rank.description,
        })
      });
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.role_id = 4;
        this.form.course_candidate = null;
        if (this.roleName == 'TEACHER') {
          this.form.assign_teacher_id = this.user.id
        }
        let candidate = await axios.post("/users", this.form);
        this.candidate = candidate.data.data;
        this.$router.push('/users')
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
