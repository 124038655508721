import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import AuthLayout from "../views/layouts/auth-layout.vue";

// Static
import HomePage from "../views/home";
// import EmployeApprove from "../views/home/employeonleave.vue";

// Auth
import LoginPage from "../views/auth/login.vue";
import CertificateVerification from "../views/auth/certificate_verification.vue";
import Certificates from "../views/certificates/index.vue";

// Companies
import CompaniesPage from "../views/companies/index.vue";
import CompanyCreatePage from "../views/companies/create.vue";
import CompanyIdPage from "../views/companies/id.vue";

// candidate
import UsersPage from "../views/users/index.vue";
import UserCreatePage from "../views/users/create.vue";
import UserIdPage from "../views/users/id.vue";
import UsersUploadPage from "../views/users/upload.vue";



//teacher
import Teacher from "../views/users/teacher.vue";
import TeacherCreate from "../views/users/teachercreate.vue";
import TeacherId from "../views/users/teacherid.vue";

//admin
import Admin from "../views/users/admin.vue";
import AdminCreate from "../views/users/admincreate.vue";
import AdminId from "../views/users/adminid.vue";

//company
import Company from "../views/users/company.vue";
import CompanyCreate from "../views/users/companycreate.vue";
import CompanyId from "../views/users/companyid.vue";


//  Course
import CoursesPage from "../views/courses/index.vue";
import CourseCreatePage from "../views/courses/create.vue";
import CourseUpdate from "../views/courses/id.vue";

//course candidate
import CandidateCourseUpdate from "../views/candidate/candidatecourseId.vue";
import CourseCandidatePage from "../views/candidate/coursecandidate.vue";
import CoursePerformance from "../views/candidate/courseperformance.vue";

// questions
import QuestionUploadPage from "../views/questions/upload.vue";
import QuestionIdPage from "../views/questions/id.vue";
import QuestionsCreate from "../views/questions/questionscreate.vue";
import QuestionIndexPage from "../views/questions/index.vue";

//courseExam
import courseExam from "../views/exams/exam.vue";
import courseCertificate from "../views/exams/certificate.vue";

//orgs
import OrgCreate from "../views/orgs/create.vue";
import OrgPage from "../views/orgs/index.vue";
import OrgUpdatePage from "../views/orgs/id.vue";

//profile
import ProfilePage from "../views/profile/profile.vue";
import CandidateProfilePage from "../views/profile/candidateprofile.vue";
import ProfileIndex from "../views/profile/profileindex.vue";

// Values
import Values from "@/views/values/index.vue";
import ValueCreate from "@/views/values/create.vue";
import ValueId from "@/views/values/id.vue";

// ValueLists
import ValueLists from "@/views/value-lists/index.vue";
import ValueListUpload from "@/views/value-lists/upload.vue";
import ValueListCreate from "@/views/value-lists/create.vue";
import ValueListId from "@/views/value-lists/id.vue";


const routes = [
  // AuthLayout
  {
    path: "/auth",
    name: "AuthLayout",
    component: AuthLayout,
    children: [{
      path: "/home",
      name: "HomePage",
      component: HomePage,
    },
    // {
    //     path: "/home/user_leaves/approved",
    //     name: "HomEmployeApprovePage",
    //     component: EmployeApprove,
    // },
    // Comapnies
    {
      path: "/companies",
      name: "CompaniesPage",
      component: CompaniesPage,
    },
    {
      path: "/companies/create",
      name: "CompanyCreatePage",
      component: CompanyCreatePage,
    },
    {
      path: "/companies/:companyId",
      name: "CompanyIdPage",
      component: CompanyIdPage,
    },

    //certificate page
    {
      path: "/certificates",
      name: "Certificates",
      component: Certificates,
    },
    // Users
    {
      path: "/users",
      name: "UsersPage",
      component: UsersPage,
    },
    {
      path: "/users/create",
      name: "UserCreatePage",
      component: UserCreatePage,
    },
    {
      path: "/users/upload",
      name: "UsersUploadPage",
      component: UsersUploadPage,
    },
    {
      path: "/users/:userId",
      name: "UserIdPage",
      component: UserIdPage,
    },

    // teacher
    {
      path: "/teacher",
      name: "Teacher",
      component: Teacher,
    },
    {
      path: "/teacher/create",
      name: "TeacherCreate",
      component: TeacherCreate,
    },
    {
      path: "/teacher/:teacherId",
      name: "TeacherId",
      component: TeacherId,
    },

    //admin
    {
      path: "/admin",
      name: "Admin",
      component: Admin,
    },
    {
      path: "/admin/create",
      name: "AdminCreate",
      component: AdminCreate,
    },
    {
      path: "/admin/:adminId",
      name: "AdminId",
      component: AdminId,
    },

    //company
    {
      path: "/company",
      name: "Company",
      component: Company,
    },
    {
      path: "/company/create",
      name: "CompanyCreate",
      component: CompanyCreate,
    },
    {
      path: "/company/:companyId",
      name: "CompanyId",
      component: CompanyId,
    },
    // Course
    {
      path: "/course",
      name: "CoursesPage",
      component: CoursesPage,
    },
    // {
    //     path: "/user-attendances",
    //     name: "UserAttendancesPage",
    //     component: UserAttendancesPage,
    // },
    {
      path: "/course/create",
      name: "CourseCreatePage",
      component: CourseCreatePage,
    },
    {
      path: "/course/:courseId",
      name: "CourseUpdate",
      component: CourseUpdate,
    },
    {
      path: "/course/candidate/:courseId",
      name: "CandidateCourseUpdate",
      component: CandidateCourseUpdate,
    },
    {
      path: "/course/candidates/:courseId",
      name: "CourseCandidatePage",
      component: CourseCandidatePage,
    },
    {
      path: "/candidate/performance",
      name: "CoursePerformance",
      component: CoursePerformance,
    },

    // question
    {
      path: "/questions/:courseId",
      name: "QuestionsCreate",
      component: QuestionsCreate,
    },
    {
      path: "/questions/upload/:courseId",
      name: "QuestionUploadPage",
      component: QuestionUploadPage,
    },
    {
      path: "/questions/:questionId/:courseId",
      name: "QuestionIdPage",
      component: QuestionIdPage,
    },
    {
      path: "/question/course/:courseId",
      name: "QuestionIndexPage",
      component: QuestionIndexPage,
    },
    //coourseExam
    // {
    //   path: "/exams/:courseId",
    //   name: "courseExam",
    //   component: courseExam,
    // },
    {
      path: "/course_certificate/:courseId",
      name: "courseCertificate",
      component: courseCertificate,
    },

    //orgs
    {
      path: "/org/create",
      name: "OrgCreate",
      component: OrgCreate,
    },
    {
      path: "/orgs",
      name: "OrgPage",
      component: OrgPage,
    },
    {
      path: "/orgs/:orgId",
      name: "OrgUpdatePage",
      component: OrgUpdatePage,
    },

    //profile
    {
      path: "/profile/:userId",
      name: "ProfilePage",
      component: ProfilePage,
    },
    {
      path: "/candidate_profile/:userId",
      name: "CandidateProfilePage",
      component: CandidateProfilePage,
    },
    {
      path: "/profile",
      name: "ProfileIndex",
      component: ProfileIndex,
    },
    // Values
    {
      path: "/values",
      name: "Values",
      component: Values,
    },
    {
      path: "/values/create",
      name: "ValueCreate",
      component: ValueCreate,
    },
    {
      path: "/values/:valueId",
      name: "ValueId",
      component: ValueId,
    },
    // ValueLists
    {
      path: "/value-lists",
      name: "ValueLists",
      component: ValueLists,
    },
    {
      path: "/value-lists/upload",
      name: "ValueListUpload",
      component: ValueListUpload,
    },
    {
      path: "/value-lists/create",
      name: "ValueListCreate",
      component: ValueListCreate,
    },
    {
      path: "/value-lists/:valueListId",
      name: "ValueListId",
      component: ValueListId,
    },
    ],
    //  //coourseExam
    //  {
    //   path: "/exams/:courseId",
    //   name: "courseExam",
    //   component: courseExam,
    // },
  },
  // End AuthLayout
  {
    path: "/exams/:courseId",
    name: "courseExam",
    component: courseExam,
  },
  // UnAuthLayout
  {
    path: "/unauth",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/verification",
        name: "CertificateVerification",
        component: CertificateVerification,
      },
    ],
  },
  //End UnAuthLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "CertificateVerification") {
    return next();
  }
  if (
    to.name === "LoginPage" ||
    to.name === "RegisterPage" ||
    to.name === "ForgotPasswordPage"
  ) {
    if (store.getters["auth/authenticated"]) {
      const roleName = store.getters["auth/roleName"];
      if (roleName != 'CANDIDATE' && roleName != 'COMPANY') {
        return next({
          name: "HomePage",
        });
      } else {
        return next({
          name: "ProfileIndex",
        });
      }
    }
    next();
  } else if (!store.getters["auth/authenticated"]) {
    return next({
      name: "LoginPage",
    });
  } else if (to.name === null) {
    return next({
      name: "HomePage",
    });
  }
  next();
});

export default router;
