<template>
    <v-main>
        <v-container fluid>
            <v-card-title class="mt-4" style="padding: 22px 15px;">
                <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
                    <v-icon dark> mdi-arrow-left </v-icon> </v-btn> <span style="font-weight: bold; font-size: 25px;">{{
                        $route.query.course_name }}
                    Candidates</span>
            </v-card-title>
            <v-row>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-card-text>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field v-model="search_keyword" label="Search ... "
                                        prepend-inner-icon="mdi-magnify" @keydown.enter="getData"
                                        @change="page = 1"></v-text-field>
                                </v-col>
                                <v-col md="3">
                                    <v-pagination v-model="page" circle :length="paginationLength">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
            <v-row v-else>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-card-title v-if="users.length != 0">

                            <v-spacer></v-spacer>
                            <v-btn class="primary mx-4" @click="generateExcel()">
                                <span v-if="isExcelloading"> Generating Excel... </span>
                                <span v-else>Generate Excel</span>
                            </v-btn>
                        </v-card-title>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Sr No</th>
                                        <th class="text-center">Course Name</th>
                                        <th class="text-center"> Subject</th>
                                        <th class="text-center">Name</th>
                                        <th class="text-center">Video count</th>
                                        <th class="text-center">Document count</th>
                                        <th class="text-center">Text count</th>
                                        <th class="text-center">phone</th>
                                        <th class="text-center">Email</th>
                                        <th class="text-center">Attempts Done</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="users.length != 0">
                                    <tr v-for="(user, i) in users" :key="`user${i}`">
                                        <td style="min-width: 85px">
                                            <v-tooltip bottom v-if="user.active == 1">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" small dark color="green">
                                                        mdi-circle
                                                    </v-icon>
                                                </template>
                                                <span>User is Active</span>
                                            </v-tooltip>
                                            <v-tooltip bottom v-if="user.active == 0">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" small dark color="red">
                                                        mdi-circle
                                                    </v-icon>
                                                </template>
                                                <span>User is InActive</span>
                                            </v-tooltip>
                                            &nbsp;
                                            {{ i + 1 }}
                                        </td>
                                        <!-- <td>{{ user.roles && user.roles.length > 0 ? user.roles[0].name : "" }}</td> -->
                                        <!-- <td>{{ user.roles[0].name }}</td> -->
                                        <td class="text-center">{{ user.course.name ? user.course.name : "" }}</td>
                                        <td class="text-center">{{ user.course.subject ? user.course.subject.description :
                                            "" }}</td>
                                        <td class="text-center">{{ user.candidate.name ? user.candidate.name : "" }}</td>
                                        <td v-if="user.course_view_count != null" class="text-center">{{
                                            user.course_view_count.video_count ?
                                            user.course_view_count.video_count : "" }}</td>
                                        <td v-else class="text-center">{{ 'no data' }}</td>
                                        <td v-if="user.course_view_count != null" class="text-center">{{
                                            user.course_view_count.document_count ?
                                            user.course_view_count.document_count : "" }}</td>
                                        <td v-else class="text-center">{{ 'no data' }}</td>
                                        <td v-if="user.course_view_count != null" class="text-center">{{
                                            user.course_view_count.text_count ?
                                            user.course_view_count.text_count : "" }}</td>
                                        <td v-else class="text-center">{{ 'no data' }}</td>
                                        <td class="text-center">{{ user.candidate.phone ? user.candidate.phone : "" }}</td>
                                        <td class="text-center">{{ user.candidate.email ? user.candidate.email : "" }}</td>
                                        <td class="text-center">{{ user.total_no_of_attempts ? user.total_no_of_attempts :
                                            "" }}</td>
                                        <td class="text-center">
                                            <v-tooltip top v-if="user.is_completed">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span> <v-icon dark v-bind="attrs" v-on="on" style="color: green;">
                                                            mdi-check-decagram </v-icon></span>
                                                </template>
                                                <span>Completed</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else-if="user.is_expired">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span> <v-icon dark v-bind="attrs" v-on="on" style="color: red;">
                                                            mdi-alert-octagon </v-icon></span>
                                                </template>
                                                <span>Expired</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span><v-icon dark v-bind="attrs" v-on="on" style="color: red;">
                                                            mdi-progress-clock
                                                        </v-icon></span>
                                                </template>
                                                <span>Pending</span>
                                            </v-tooltip>
                                            <!-- <span v-if="user.is_completed"> <v-icon dark v-bind="attrs" v-on="on" style="color: green;">
                                                    mdi-check-decagram </v-icon></span>
                                            <span v-else-if="user.is_expired"> <v-icon dark style="color: red;">
                                                    mdi-alert-octagon </v-icon></span>
                                            <span v-else> <v-icon dark style="color: red;"> mdi-progress-clock
                                                </v-icon></span> -->
                                        </td>
                                        <td class="text-right">
                                            <v-btn class="mx-2" fab x-small color="primary"
                                                :to="{ path: '/candidate/performance', query: { candidate_id: user.candidate.id, course_id: user.course.id } }">
                                                <v-icon dark> mdi-poll </v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="12" class="text-center">No data found</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
  
<script>
import axios from "axios";
export default {
    name: "usersPage",
    data() {
        return {
            form: {
                active: "",
                supervisor_id: "",
                role_id: "",
                designation_id: "",
            },
            page: 1,
            serialNoStarting: 1,
            paginationLength: 0,
            count: 0,
            search_keyword: "",
            users: [],
            statuses: [
                {
                    id: 0,
                    text: "Select Active Status",
                    value: "",
                },
                { id: "ACTIVE", text: "ACTIVE", value: "1" },
                { id: "INACTIVE", text: "INACTIVE", value: "0" },
            ],

            Teachers: [
                {
                    id: 0,
                    text: "Select Teacher",
                    value: "",
                },
            ],
            courseName: "",
            isLoading: false,
            isExcelloading: false,
            ExportUsers: [],
            Export_users: [],

        };
    },
    watch: {
        page: "getData",
    },
    mounted() {

        this.getData();
        this.getMasters();
    },
    methods: {
        async getMasters() {
            let masters = await axios.get(`users/masters`);
            masters = masters.data;
            // Roles
            masters.teachers.forEach((teacher) => {
                this.Teachers.push({
                    id: teacher.id,
                    text: teacher.name,
                    value: teacher.id,
                });
            });

        },
        async getData() {
            console.log(this.form.supervisor_id);
            if (this.roleName == 'TEACHER') {
                this.teacher_id = this.user.id
            }
            if (this.roleName == 'COMPANY') {
                this.company_user_id = this.user.id
            }
            this.isLoading = true;
            this.search_keyword = this.search_keyword ? this.search_keyword : "";
            this.active = this.active ? this.active : "";
            this.role_id = this.role_id ? this.role_id : "";
            this.teacher_id = this.teacher_id ? this.teacher_id : "";
            this.company_user_id = this.company_user_id ? this.company_user_id : "";
            let users = [];
            let query =
                "page=" +
                this.page +
                "&rowsPerPage=" +
                this.rowsPerPage +
                "&search_keyword=" +
                this.search_keyword +
                "&company_user_id=" +
                this.company_user_id +
                "&assigned_teacher_id=" +
                this.teacher_id +
                "&course_id=" +
                this.$route.params.courseId +
                "&is_active=" +
                this.form.active;
            users = await axios.get(`course_candidates?${query}`);
            this.users = users.data.data;
            this.courseName = this.users.length > 0 ? this.users[0].course.name : "";
            this.count = users.data.count;
            this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
            this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
            this.isLoading = false;
        },
        //  async Delete_this(id) {
        //   let check = confirm("Are you sure you want to delete the data");
        //   if (check) {
        //     try {
        //       this.isLoading = true;
        //       await axios.delete(`users/${id}`);
        //       this.isLoading = false;
        //       this.getData();
        //     } catch (e) {
        //       this.isLoading = false;
        //     }
        //   }
        // },


        async generateExcel() {
            this.isExcelloading = true;
            let query =
                "search_keyword=" +
                this.search_keyword +
                "&is_active=" +
                this.form.active +
                "&roleId=" +
                this.form.role_id;

            try {
                let response = await axios.get(`usersExports?${query}`);
                if (response && response.data && response.data.data) {
                    window.open(`${response.data.data}`, "_blank");
                } else {
                    console.error("Invalid response data:", response.data);
                }
            } catch (error) {
                console.error("Error fetching Excel data:", error);
            }
            this.isExcelloading = false;
        },
    },
};
</script>
  