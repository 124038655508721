<template>
    <v-main>
        <v-row>
            <v-card-title class="mt-4">
                <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
                    <v-icon dark> mdi-arrow-left </v-icon> </v-btn> <v-btn class="mx-2" fab x-small color="white"
                    :to="{ path: `/questions/${this.$route.params.courseId}`, query: { course_name: $route.query.course_name } }">

                    <v-icon dark> mdi-plus </v-icon>
                </v-btn><v-btn class="mx-2" fab x-small color="primary"
                    :to="`/questions/upload/${this.$route.params.courseId}`">
                    <v-icon dark> mdi-upload </v-icon> </v-btn> <v-btn :loading=isExcelloading class="mx-2" fab x-small
                    color="primary" @click="generateExcel()">
                    <v-icon>mdi-database-export</v-icon>
                </v-btn>{{ $route.query.course_name }} Questions
            </v-card-title>
            <v-col md="12">
                <v-card elevation="6">
                    <v-simple-table fixed-header>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">sr</th>
                                    <th class="text-left">Question</th>
                                    <th class="text-left">Correct Answer</th>
                                    <th class="text-left">Marks</th>
                                    <th style="width:18%;" class="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="questions.length !== 0">
                                <tr v-for="(question, i) in questions" :key="`question${i}`">
                                    <td style="min-width: 85px">
                                        <v-tooltip bottom v-if="question.is_active === 1">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" small dark color="green">
                                                    mdi-circle
                                                </v-icon>
                                            </template>
                                            <span>User is Active</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-else>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" small dark color="red">
                                                    mdi-circle
                                                </v-icon>
                                            </template>
                                            <span>User is InActive</span>
                                        </v-tooltip>
                                        &nbsp;
                                        {{ i + 1 }}
                                    </td>
                                    <td class="text-left" v-html="question.question || ''">
                                    </td>
                                    <td class="text-left">
                                        {{ question.correct_answer ? question.correct_answer : "" }}
                                    </td>
                                    <td class="text-left">{{ question.marks ? question.marks : "" }}</td>
                                    <td style="display: flex; align-items: center; justify-content: space-between;">
                                        <v-switch v-model="question.is_active" inset
                                            :label="`Active: ${question.is_active === 1 ? 'YES' : 'NO'}`"
                                            @click="openConfirmationDialog(question)"></v-switch>
                                        <v-btn depressed color="error" class="mx-2" fab dark small
                                            style="width: 33px; height: 33px;" @click="DeleteConfirmation(question)">
                                            <v-icon dark style="font-size: 20px;">
                                                mdi-cancel
                                            </v-icon>
                                        </v-btn>
                                        <v-btn class="mx-2" fab x-small color="primary"
                                            :to="{ path: `/questions/${question.id}/${$route.params.courseId}`, query: { course_name: $route.query.course_name } }">
                                            <!-- :to="`/questions/${question.id}/${$route.params.courseId}`"> -->
                                            <v-icon dark> mdi-pencil </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="8" class="text-center">No data found</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-if="dialogVisible" v-model="dialogVisible" max-width="400">
            <v-card>
                <v-card-title class="headline">Confirmation</v-card-title>
                <v-card-text>
                    {{ confirmationText }}
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" @click="confirmToggle()">
                        Yes
                    </v-btn>
                    <v-btn @click="closeConfirmationDialog" color="red darken-1">
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>
  
<script>
import axios from "axios";

export default {
    name: "QuestionPage",
    data() {
        return {
            questions: [],
            dialogVisible: false,
            currentQuestion: null,
            isExcelloading: false
        };
    },
    mounted() {
        console.log('red');
        this.getData();
    },
    methods: {
        async getData() {
            let questions = await axios.get(`/course_questions?course_id=${this.$route.params.courseId}`);
            this.questions = questions.data.data;
            console.log(this.questions);
        },
        openConfirmationDialog(question) {
            this.currentQuestion = question;
            this.confirmationText = question.is_active == 1 ? "Are you sure you want to Activate ?" : "Are you sure you want to Deactivate ?";
            this.dialogVisible = true;
        },
        DeleteConfirmation(question) {
            this.currentQuestion = question;
            this.currentQuestion.is_deleted = true;
            this.confirmationText = "Are you sure you want to Delete ?";
            this.dialogVisible = true;
        },
        closeConfirmationDialog() {
            this.dialogVisible = false;
        },
        async confirmToggle() {
            if (this.currentQuestion) {
                let payload = {
                    id: this.currentQuestion.id,
                    is_active: this.currentQuestion.is_active,
                    is_deleted: this.currentQuestion.is_deleted,
                };
                try {
                    let response = await axios.post('/course_questions', payload);
                    if (response != null) {
                        this.dialogVisible = false;
                        this.getData();
                    } else {
                        console.error('failed:', response.data.message);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        },
        async generateExcel() {
            this.isExcelloading = true;
            try {
                let response = await axios.get(`questionsExports?course_id=${this.$route.params.courseId}`);
                if (response && response.data && response.data.data) {
                    window.open(`${response.data.data}`, "_blank");
                } else {
                    console.error("Invalid response data:", response.data);
                }
            } catch (error) {
                console.error("Error fetching Excel data:", error);
            }
            this.getData();
            this.isExcelloading = false;
        },
    },
};
</script>
  