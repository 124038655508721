<template>
    <v-row>
        <v-col sm="4">
            <v-card color="#85C1E9" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="text-h5" v-text="counts.totalUser"></v-card-title>

                        <v-card-subtitle v-text="'TOTAL CANDIDATES'"></v-card-subtitle>
                        <v-card-actions>
                            <v-btn class="ml-2 mt-5" outlined rounded small target="_blank" to="/users">
                                View List
                            </v-btn>
                        </v-card-actions>
                    </div>
                    <v-avatar class="ma-90" size="140">
                        <v-icon class="imgStyle" x-large dark>mdi-account-multiple</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>
        <v-col sm="4">
            <v-card color="#85C1E9" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="text-h5" v-text="counts.totalTeacher"></v-card-title>

                        <v-card-subtitle v-text="'TOTAL TEACHERS'"></v-card-subtitle>

                        <!-- <v-card-actions>
                            <v-btn class="ml-2 mt-5" outlined rounded small target="_blank" :to="{ path: '/supervisor' }">
                                View List
                            </v-btn>
                        </v-card-actions> -->
                    </div>

                    <v-avatar class="ma-90" size="140" tile>
                        <v-icon class="imgStyle" x-large dark>mdi-account-multiple</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>
        <v-col sm="4">
            <v-card color="#85C1E9" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="text-h5" v-text="counts.totalCourse"></v-card-title>

                        <v-card-subtitle v-text="'TOTAL COURSES'"></v-card-subtitle>

                        <!-- <v-card-actions>
                            <v-btn class="ml-2 mt-5" outlined rounded small target="_blank"
                                :to="{ path: '/user-leaves', query: { is_approved: 1, current_month: current_month } }">
                                View List
                            </v-btn>
                        </v-card-actions> -->
                    </div>

                    <v-avatar class="ma-3" size="125" tile>
                        <v-icon class="imgStyle" x-large dark>mdi-calendar-check</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>
        <v-col sm="4">
            <v-card color="#85C1E9" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="text-h5" v-text="counts.totalCourseCompletedCandidate"></v-card-title>
                        <v-card-subtitle v-text="'TOTAL COMPLETED COURSE FROM CANDDIDATE'"></v-card-subtitle>
                        <!-- <v-card-actions>
                            <v-btn class="ml-2 mt-5" outlined rounded small target="_blank"
                                :to="{ path: '/user-leaves', query: { is_approved: 0, current_month: current_month } }">
                                View List
                            </v-btn>
                        </v-card-actions> -->
                    </div>

                    <v-avatar class="ma-3" size="125" tile>
                        <v-icon class="imgStyle" x-large dark>mdi-calendar-remove</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>
        <!-- <v-col sm="4">
            <v-card color="#85C1E9" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="text-h5" v-text="counts.totaluser_present_today"></v-card-title>
                        <v-card-subtitle v-text="'TOTAL PRESENT TODAY'"></v-card-subtitle>
                        <v-card-actions>
                            <v-btn class="ml-2 mt-5" outlined rounded small target="_blank"
                                :to="{ path: '/user-attendances', query: { created_at: current_date, session_type_id: 4 } }">
                                View List
                            </v-btn>
                        </v-card-actions>
                    </div>

                    <v-avatar class="ma-3" size="125" tile>
                        <v-icon class="imgStyle" x-large dark>mdi-account-multiple</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>
        <v-col sm="4">
            <v-card color="#85C1E9" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="text-h5" v-text="counts.totaluser_absent_today"></v-card-title>
                        <v-card-subtitle v-text="'TOTAL ABSENT TODAY'"></v-card-subtitle>
                        <v-card-actions>
                            <v-btn class="ml-2 mt-5" outlined rounded small target="_blank"
                                :to="{ path: '/user-attendances', query: { created_at: current_date, session_type_id: 5 } }">

                                View List
                            </v-btn>
                        </v-card-actions>
                    </div>

                    <v-avatar class="ma-3" size="125" tile>
                        <v-icon class="imgStyle" x-large dark>mdi-account-multiple</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>
        <v-col sm="3">
            <v-card color="#85C1E9" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="text-h5" v-text="counts.totaluser_onleave_today"></v-card-title>
                        <v-card-subtitle v-text="'EMPLOYE ON LEAVE TODAY'"></v-card-subtitle>
                        <v-card-actions>
                            <v-btn class="ml-2 mt-5" outlined rounded small target="_blank"
                                :to="{ path: '/user-leaves', query: { is_approved: 1, leave_date: current_date } }">
                                View List
                            </v-btn>
                        </v-card-actions>
                    </div>

                    <v-avatar class="ma-3" size="125" tile>
                        <v-icon class="imgStyle" x-large dark>mdi-account-check</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col> -->
    </v-row>
</template>
  
<script>
import axios from "axios";

export default {
    name: "SACounts",
    data() {
        return {
            counts: {
            },
            roleId: "",
            current_date: new Date(),
            current_month: new Date()
        };
    },
    mounted() {
        this.getData();
        this.getCurrentDate();
        this.roleId = this.user.roles[0].id;
        console.log(this.current_month)
    },
    methods: {
        async getData() {
            try {
                let query = this.roleId = this.roleId ? this.roleId : "";
                let response = await axios.get(`/admin_dashboard?${query}`);
                console.log(response);
                this.counts = response.data;
                console.log(this.counts);
            } catch (error) {
                console.error("Error fetching data:", error);
            }

        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            this.current_date = `${year}-${month}-${day}`;
            this.current_month = `${month}`;
        }
    },
};
</script>
  