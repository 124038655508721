import store from '@/store'
import axios from 'axios'

// const baseURL = 'http://localhost:8084';
// const baseURL = 'http://192.168.0.181:8080';
const baseURL = 'https://api.sta.aaibuzz.com';

axios.defaults.baseURL = baseURL + '/api';
store.dispatch('settings/setMediaUrl', 'https://az3-medias.s3.ap-south-1.amazonaws.com/')
// store.dispatch('settings/setMediaUrl', baseURL + '/storage/')
store.dispatch('settings/setBaseUrl', baseURL)

axios.interceptors.request.use(config => {
    store.dispatch('errors/setErrors', {})
    store.dispatch('errors/setStatus', {})
    return config
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
    return response
}, error => {
    const errors = error.response.data.errors
    Object.keys(errors).forEach((key) => {
        errors[key] = errors[key][0]
    })
    store.dispatch('errors/setErrors', errors)
    store.dispatch('errors/setStatus', error.response.status)
    return Promise.reject(error)
})
