<template>
    <v-main>
        <v-container>

            <body>
                <div class="container pm-certificate-container">
                    <div class="outer-border"></div>
                    <div class="inner-border"></div>
                    <div class="pm-certificate-border col-xs-12">
                        <div class="pm-certificate-header">
                            <v-row class="mb-6">
                                <v-col sm="12" class="text-center" style="display: flex; justify-content: space-around;">
                                    <div>
                                        <v-img width="100" :src="mediaUrl + form.company.logo_image_path" crossOrigin="">
                                        </v-img>
                                    </div>
                                    <div>
                                        <h2 class="text-uppercase">{{ form.company.name }}</h2>
                                        <strong>{{ form.company.address }}</strong>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <v-container>
                            <div class="pm-certificate-block">
                                <v-row style="justify-content: end;">
                                    <div class="certificate-content">
                                        <span>CERT. NO </span><span class="blank-field text-center" id="crtNoField">{{
                                            form.course_candidate.cert_no }}</span>
                                    </div>
                                </v-row>
                                <v-row>
                                    <div class="certificate-content">
                                        <span>This is Certify that Mr./Mrs </span><span style="width: 492px;"
                                            class="blank-field text-center">{{ form.candidate.name
                                            }}</span>
                                    </div>
                                </v-row>
                                <v-row>
                                    <div class="certificate-content">
                                        <span>Date Of Birth </span><span class="blank-field text-center blank-field3">{{
                                            form.candidate.dob }}</span><span>
                                            Holder
                                            of C.D.C No </span><span style="width: 316px;"
                                            class="blank-field text-center">{{
                                                form.candidate.cdc }}</span>
                                    </div>
                                </v-row>
                                <v-row>
                                    <div class="certificate-content">
                                        <span>and Passport No </span><span class="blank-field text-center blank-field3">{{
                                            form.candidate.passport_no }}</span><span> ID
                                        </span><span class="blank-field text-center " style="width: 414px;">{{
                                            form.candidate.id
                                        }}</span>
                                    </div>
                                </v-row>
                                <v-row>
                                    <div class="certificate-content">
                                        <span>Certificate of Competency ,(if any) Grade </span><span
                                            class="blank-field text-center" style="width: 176px;">{{ form.total_percentage
                                            }}</span><span> No. </span><span class="blank-field text-center blank-field6">{{
    form.total_marks }}</span>
                                    </div>
                                </v-row>
                                <v-row>
                                    <div class="certificate-content">
                                        <span>Indian National Data base (INDOS NO.) </span><span
                                            class="blank-field text-center blank-field5">{{ form.candidate.indos }}</span>
                                        has
                                        successfully completed training
                                        course for Certificate of proficieny in.
                                    </div>
                                </v-row>
                                <v-row style="justify-content: center; margin-top: 40px;">
                                    <div class="certificate-content">
                                        <h2 style="color: #6ea3cd; text-decoration: underline;">TRAINING FOR TRAINERS AND
                                            ASSESORS</h2>
                                    </div>
                                </v-row>
                                <v-row style="justify-content: center;" class="px-9">
                                    <div class="certificate-content">
                                        <p v-html="form.course.course_content"></p>
                                    </div>
                                </v-row>
                                <v-row class="px-9 mt-9">
                                    <div class="certificate-content">
                                        <span><strong>Held From</strong> </span><span
                                            class="blank-field text-center blank-field7">{{
                                                form.course.user.name }}</span><span><strong> To </strong></span><span
                                            class="blank-field text-center blank-field8">{{ form.candidate.name
                                            }}</span>
                                    </div>
                                </v-row>
                                <v-row class="px-5 mt-15" style="align-items: center; font-family: 'PT Serif', serif;">
                                    <v-row style="justify-content: end; margin-bottom: 50px;">
                                        <v-col sm="4" class="image-container mt-4">
                                            <v-container>
                                                <span class="empty-box">

                                                    <v-img style="max-height: 164px;" contain
                                                        :src="mediaUrl + form.candidate.image_path">
                                                    </v-img>
                                                </span>
                                            </v-container>
                                        </v-col>
                                        <v-col sm="4" class="text-center">
                                            <span class="blank-field text-center blank-field-new blank-field7"></span> <br>
                                            <span><strong> Signature of Candidate</strong> </span>
                                        </v-col>
                                        <v-col sm="4" class="text-center">
                                            <span class="blank-field text-center blank-field-new blank-field8">{{
                                                form.course.user.name }}</span><br>
                                            <span><strong> Course-in-Charge</strong> </span>
                                        </v-col>
                                    </v-row>
                                    <v-row style="justify-content: end;margin-bottom: 50px;">
                                        <v-col sm="4" class="text-center">
                                            <span class="blank-field text-center blank-field-new blank-field7">{{
                                                formatDate(form.created_at) }}</span> <br>
                                            <span><strong> Date of Issue</strong> </span>
                                        </v-col>
                                        <v-col sm="4" class="text-center">
                                            <span class="blank-field text-center blank-field-new blank-field8"></span><br>
                                            <span><strong> Principal/Director </strong></span>
                                        </v-col>
                                    </v-row>
                                </v-row>
                                <v-row class=" justify-center">
                                    <div class="certificate-content" style="font-size: xx-small;">
                                        <p>
                                            obcaecati ullam voluptates laborum repudiandae exercitationem repellendus
                                            similique, soluta eius nesciunt quas aperiam hic quidem.
                                        </p>
                                    </div>
                                </v-row>
                            </div>
                        </v-container>

                    </div>
                </div>
            </body>
        </v-container>
        <v-btn style="float: right;" color="primary" @click="downloadCertificate">Download Certificate</v-btn>
    </v-main>
</template>


<script>
import axios from "axios";
export default {
    name: "CourseCertificate",
    data() {
        return {
            form: {

            },
            base64Image: null,
        }
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        formatDate(dateTime) {
            const date = new Date(dateTime);
            return date.toISOString().split('T')[0]; // Get the date part
        },
        async getData() {
            let query =
                'candidate_id=' +
                this.user.id +
                '&course_id=' +
                this.$route.params.courseId +
                '&is_certificate=1'
                ;
            let response = await axios.get(`/course_exams?${query}`
            )
            this.form = response.data.data;
        },
        async downloadCertificate() {
            let query =
                'candidate_id=' +
                this.user.id +
                '&is_download=' +
                true +
                '&course_id=' +
                this.$route.params.courseId +
                '&is_certificate=1'
                ;
            let response = await axios.get(`/course_exams?${query}`, {
                responseType: "blob",
            }
            ).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
            });
            this.form = response.data.data;
        },
    }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Serif&family=Playfair+Display:wght@500;700&display=swap');

.pm-certificate-border {
    border: 3px solid #0072c1 !important;
    padding: 20px;
}

.certificate-content {
    float: right;
    margin-bottom: 10px;
    margin-top: 10px
}

.blank-field {
    border-bottom: 2px solid black;
    display: inline-block;
    width: 130px;
}

.blank-field2 {
    width: 400px;
}

.blank-field3 {
    width: 130px;
}

.blank-field4 {
    width: 286px;
}

.blank-field5 {
    width: 230px;
}

.blank-field6 {
    width: 180px;
}

.blank-field7 {
    width: 255px;
    text-align: center;
}

.blank-field8 {
    width: 255px;
    text-align: center;
}

.cursive {
    font-family: 'Pinyon Script', cursive;
}

.sans {
    font-family: 'Open Sans', sans-serif;
}

.bold {
    font-weight: bold;
}

.block {
    display: block;
}

.underline {
    border-bottom: 1px solid #777;
    padding: 5px;
    margin-bottom: 15px;
}

.margin-0 {
    margin: 0;
}

.padding-0 {
    padding: 0;
}

.pm-empty-space {
    height: 40px;
    width: 100%;
}

body {
    padding: 20px 0;
    background: #ccc;
}

.pm-certificate-container {
    position: relative;
    width: 800px;
    background-color: white;
    padding: 30px;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.blank-field-new {
    width: 200px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.empty-box {
    display: block;
    width: 170px;
    height: 170px;
    border: 1px solid black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.pm-certificate-heade h2 {
    color: #0072c1;
    font-family: 'Open Sans', sans-serif;
    ;
}

.pm-certificate-heade strong {
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
}

/* .certificate-content {
    font-family: 'PT Serif', serif;
} */

.certificate-content {
    font-family: 'Playfair Display', serif;
}

@media print {

    /* Add styles to make the certificate print-friendly */
    body {
        margin: 0;
        padding: 0;
    }

    /* Add any other print-specific styles here */
}
</style>
