<template>
    <v-main>
        <v-card-title style="padding: 20px 3px;">
            <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
                <v-icon dark>mdi-arrow-left</v-icon>
            </v-btn>
            Course
        </v-card-title>
        <v-container fluid>
            <v-row align="center">
                <v-col sm="6" style="flex: 0 0 50% !important;">
                    <div class="video-container" style="flex-direction: column; align-items: start;">
                        <h2 class="course-header">Course Video</h2>
                        <video v-if="form.video_path" :src="`${mediaUrl}${form.video_path}`" controls width="86%"
                            height="400" @play="recordVideoView"></video>
                    </div>
                </v-col>
                <v-col sm="6" style="flex: 0 0 50% !important;">
                    <div class="video-container" style="flex-direction: column; align-items: start;">
                        <h2 class="course-info">Course Name: <span>{{ form.name }}</span></h2>
                        <h2 class="course-info">Passing Marks:<span>{{ form.passing_marks }}%</span></h2>
                        <h2 class="course-info">Exam Duration:<span>{{ form.exam_duration }}min</span></h2>
                        <h2 class="course-info">Number of Quiz: <span>{{ form.no_of_quiz }}</span></h2>
                    </div>
                </v-col>
            </v-row>

            <!-- Document Section -->
            <v-row ref="documentSection" style="margin-top: 40px;margin-bottom: 25px;">
                <v-col sm="12">
                    <h2 class="mb-4 course-header">Course Document</h2>
                    <template v-if="form.document_path">
                        <template v-if="doc_format.includes(getFileExtension(form.document_path))">
                            IN
                            <VueDocPreview :value="`${mediaUrl}${form.document_path}`" type="office" />
                        </template>
                        {{ getFileExtension(form.document_path) }}
                        <template v-if="getFileExtension(form.document_path) == 'PDF'">
                            <iframe id="fred" style="border:1px solid #666CCC" title="PDF in an i-Frame"
                                :src="`${mediaUrl}${form.document_path}`" frameborder="1" scrolling="auto" width="100%"
                                height="600px"></iframe>
                        </template>
                        <a :href="`${mediaUrl}${form.document_path}`" target="_blank">View Document</a>
                    </template>
                </v-col>
            </v-row>

            <!-- Text Section -->
            <v-row ref="courseDescriptionSection" style="margin-top: 25px;margin-bottom: 25px;">
                <v-col sm="12">
                    <h2 class="mb-4 course-header">Course Description</h2>
                    <div v-if="form.text" style="padding: 0px 20px;">
                        <p v-html="form.text"></p>
                    </div>
                </v-col>
            </v-row>

            <!-- Button to Start Exam -->
            <v-row v-if="form.course_candidates">
                <v-col sm="12">
                    <div style="float: right; display: flex; flex-direction: column;">
                        <span v-if="form.course_candidates[0].is_completed == false">You have {{
                            form.course_candidates[0].no_of_attempt }} attempts remaining.</span> <br>
                        <v-btn
                            v-if="form.course_candidates[0].is_expired == false && form.course_candidates[0].is_completed == false && form.course_candidates[0].no_of_attempt != 0"
                            @click="showConfirmationDialog" color="primary">Start Exam</v-btn>
                        <v-btn
                            v-else-if="form.course_candidates[0].is_expired == false && form.course_candidates[0].is_completed == true"
                            color="primary">Course Completed</v-btn>
                        <v-btn v-else color="red">Expired</v-btn>
                    </div>
                </v-col>
            </v-row>

            <v-dialog v-model="confirmationDialog" max-width="400" v-if="form.course_candidates">
                <v-card>
                    <v-card-title>Course Exam</v-card-title>
                    <v-card-text>Are you sure you want to proceed? You have {{ form.course_candidates[0].no_of_attempt }}
                        attempts remaining.</v-card-text>
                    <v-card-actions>
                        <v-btn @click="confirmationDialog = false" color="primary">Cancel</v-btn>
                        <v-btn @click="startExam" color="primary">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-main>
</template>
  
<script>
import axios from 'axios';
export default {
    name: 'CandidateCourseUpdate',
    data() {
        return {
            form: {
                is_active: true,
            },
            isLoading: false,
            Subjects: [],
            doc_format: ['DOCX', 'PPTX', 'XLSX'],
            confirmationDialog: false,
            hasVisitedBefore: false,
        };
    },
    mounted() {
        localStorage.removeItem('examSubmitted');
        if (this.roleName == 'CANDIDATE') {
            this.candidate_id = this.user.id;
        }
        const visitedBefore = localStorage.getItem('coursePageVisited');
        if (!visitedBefore) {
            this.DocumentAndTextView();
            localStorage.setItem('coursePageVisited', 'true');
        }
        this.getData();
    },
    beforeDestroy() {
        // Clear the localStorage when the component is about to be destroyed
        localStorage.removeItem('coursePageVisited');
    },
    methods: {
        showConfirmationDialog() {
            this.confirmationDialog = true;
        },
        async recordVideoView() {
            let payload = {
                candidate_id: this.user.id,
                course_id: this.$route.params.courseId,
                video_count: 1,
            };
            await axios.post('/course_view_count', payload);
        },
        async DocumentAndTextView() {
            let payload = {
                candidate_id: this.user.id,
                course_id: this.$route.params.courseId,
                document_count: 1,
                text_count: 1,
            };
            await axios.post('/course_view_count', payload);
        },
        async startExam() {
            this.$router.push(`/exams/${this.$route.params.courseId}`);
            let payload = {
                id: this.user.id,
                course_id: this.$route.params.courseId,

            };
            await axios.post(`/course_candidates`, payload);
        },
        async getData() {
            this.isLoading = true;
            let query = 'candidate_id=' + this.candidate_id;
            let form = await axios.get(`/course/${this.$route.params.courseId}?${query}`);
            this.form = form.data.data;
            console.log(this.form.course_candidates);
            this.isLoading = false;
        },
        getFileExtension(path) {
            if (path) {
                let path_array = path.split('/');
                let image_name = path_array[3];
                let extension = image_name?.split('.')[1].toUpperCase();
                return extension;
            }
        },
    },
};
</script>
  
<style scoped>
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.video-container video {
    max-width: 100%;
    max-height: 100%;
}

.course-header {
    display: inline-block;
    border-bottom: 2px solid #1976D2;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.course-info {
    margin-bottom: 20px;
}

.course-info span {
    color: #959ea7;
    font-size: 21px;
    padding-left: 10px;
}
</style>
  