<template>
    <v-main>
        <v-container>
            <!-- Exam Header -->
            <v-row justify="center">
                <v-col>
                    <h1 class="text-h5 font-weight-bold">{{ courses[0].name }} Exam</h1><br>
                    <h3 class="text-subtitle-1">Instructions: Follow the instructions carefully.</h3>
                </v-col>
                <v-col>
                    <h2 style="float: right; font-size: 2.5rem !important;" class="headline">{{ formattedTime }}</h2>
                </v-col>
            </v-row>
            <!-- Question Section -->
            <v-row v-for="(question, index) in      shuffledQuestions     " :key="index" class="mt-4">
                <v-col md="8">
                    <!-- Display Question -->
                    <v-card elevation="6" style="padding:20px;">

                        <v-card-title style="padding: 0px;">
                            <h2 style="display: flex;" class="headline" v-html="`Q${index + 1} :  ${question.question}`">
                            </h2>
                        </v-card-title>
                        <!-- Display Options -->
                        <v-row>
                            <v-col>
                                <v-checkbox :label="question.option_1" :value="question.option_1" class="mb-2"
                                    v-model="selectedOptions[index]"></v-checkbox>
                            </v-col>
                            <v-col>
                                <v-checkbox :label="question.option_2" :value="question.option_2" class="mb-2"
                                    v-model="selectedOptions[index]"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-checkbox :label="question.option_3" :value="question.option_3" class="mb-2"
                                    v-model="selectedOptions[index]"></v-checkbox>
                            </v-col>
                            <v-col>
                                <v-checkbox :label="question.option_4" :value="question.option_4" class="mb-2"
                                    v-model="selectedOptions[index]"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <!-- Exam Navigation Buttons -->
            <v-row style="float:right;margin-bottom:50px;margin:20px;" class="mt-4">
                <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
                <v-btn color="primary" v-else x-large @click="openSubmitDialog">Submit</v-btn>
            </v-row>
        </v-container>
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title class="headline">Confirm Submission</v-card-title>
                <v-card-text>
                    Are you sure you want to submit the exam?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="dialog = false">Cancel</v-btn>
                    <v-btn color="primary" dark @click="submitExam">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Result Dialog -->
        <v-dialog v-if="result != null" v-model="resultDialog" max-width="400">
            <v-card>
                <v-card-title v-if="exam_pass" class="headline" style="color: green;">Congratulations!</v-card-title>
                <v-card-title v-else class="headline" style="color: red;">Sorry you Fail!</v-card-title>
                <v-card-text>
                    <p v-if="exam_pass">You have passed the exam for {{ courses[0].name }}.</p>
                    <p v-else>You have failed the exam for {{ courses[0].name }}.</p>
                    <p>You Score Marks: {{ score_marks }} . </p>
                    <p>You Score : {{ score_percentage }}% </p>
                    <p> passing percentage is : {{ passing_marks }}% .</p>
                </v-card-text>
                <v-card-actions v-if="exam_pass">
                    <a target="_blank" @click="CertificateGenerate"
                        :href="`${baseUrl}/api/course_exams?candidate_id=${user.id}&course_id=${courses[0].id}&is_download=true`"
                        class="certificate-generate">Generate
                        Certificate</a>
                </v-card-actions>
                <v-card-actions v-else>
                    <v-btn color="primary" @click="CertificateGenerate">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>
<script>
import axios from "axios";

export default {
    name: "CourseExam",
    data() {
        return {
            questions: [],
            selectedOptions: [],
            courses: [],
            shuffledQuestions: [],
            examDuration: 0,
            timer: null,
            dialog: false,
            resultDialog: false,
            result: '',
            passing_marks: '',
            score_marks: '',
            score_percentage: '',
            exam_pass: false,
            isRefreshing: false,
            reloadCount: 0,
            storedSeconds: '',
            storedMinutes: '',
            isExamSubmitted: false,
        };
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.examDuration / 60);
            const seconds = this.examDuration % 60;
            if (this.storedMinutes !== '' && this.storedSeconds !== '') {
                return `${this.storedMinutes}:${this.storedSeconds < 10 ? '0' : ''}${this.storedSeconds}`;
            } else {
                return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            }
        },
    },
    mounted() {
        this.disableBackButton();
        window.addEventListener("beforeunload", this.beforeUnloadHandler);
        window.addEventListener("visibilitychange", this.handleVisibilityChange);
        const storedTime = localStorage.getItem('remainingTime');
        if (storedTime) {
            const [storedMinutes, storedSeconds] = storedTime.split(':');
            this.storedMinutes = storedMinutes;
            this.storedSeconds = storedSeconds;
        }
        const examSubmitted = localStorage.getItem('examSubmitted');
        if (examSubmitted === 'true') {
            localStorage.removeItem('examSubmitted');
            this.$router.push('/course');
        } else {
            this.getData();
        }
    },
    beforeDestroy() {
        window.onbeforeunload = null;
        this.enableBackButton();
        window.removeEventListener("visibilitychange", this.handleVisibilityChange);
        localStorage.removeItem('remainingTime');
        localStorage.removeItem('examSubmitted');
        clearInterval(this.timer);
    },
    // beforeRouteLeave(to, from, next) {
    //     if (localStorage.getItem('examSubmitted') !== 'true') {
    //         const confirmMessage = "if you Leave the page exam will submit automatically. Are you sure you want to submit?";
    //         if (window.confirm(confirmMessage)) {
    //             localStorage.removeItem('remainingTime');
    //             clearInterval(this.timer);
    //             this.submitExam(); // Submit the exam API
    //             next();
    //         } else {
    //             next(false);
    //         }
    //     } else {
    //         next();
    //     }
    // },
    methods: {
        confirmLeave(event) {
            const confirmationMessage = "Are you sure you want to leave?";
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        },
        CertificateGenerate() {
            if (this.exam_pass == true) {
                this.$router.push(`/course`);

            } else {
                this.$router.push(`/course`);
            }
        },
        async submitExam() {
            this.isExamSubmitted = true;
            const ExamSelectoption = this.shuffledQuestions.map((question, index) => ({
                question_id: question.id,
                selected_option: this.selectedOptions[index] || '', // Access the selected option
                marks: question.marks, // Access the marks
            }));
            let payload = {
                course_id: this.$route.params.courseId,
                candidate_id: this.user.id,
                selected: ExamSelectoption,
            }
            let response = await axios.post(`/course_exams`, payload);
            this.passing_marks = response.data.passing_marks;
            this.score_marks = response.data.score_marks;
            this.score_percentage = response.data.score_percentage;

            if (this.score_percentage >= this.passing_marks) {
                this.exam_pass = true
            } else {
                this.exam_pass = false
            }
            localStorage.setItem('examSubmitted', 'true');
            this.resultDialog = true;

        },
        disableBackButton() {
            window.history.pushState(null, document.title, window.location.href);

            window.onpopstate = function () {
                window.history.pushState(null, document.title, window.location.href);
            };
        },
        enableBackButton() {
            window.onpopstate = null;
        },
        openSubmitDialog() {
            this.dialog = true;
        },
        closeSubmitDialog() {
            this.dialog = false;
        },
        handleVisibilityChange() {
            if (document.visibilityState === "hidden") {
                console.log('submite')
            }
        },
        async getData() {
            this.isLoading = true;
            let questions = await axios.get(`/course_questions?course_id=${this.$route.params.courseId}&is_exam=true`);
            this.questions = questions.data.data;
            this.courses = questions.data.data.map(item => item.course);
            // Get the number of questions to display from the course
            const numberOfQuestions = parseInt(this.courses[0].no_of_quiz);
            // Get the exam duration in seconds
            this.examDuration = parseInt(this.courses[0].exam_duration) * 60;
            // Shuffle the questions
            this.shuffledQuestions = this.shuffle(this.questions).slice(0, numberOfQuestions);
            if (this.storedMinutes !== '' && this.storedSeconds !== '') {
                this.examDuration = parseInt(this.storedMinutes) * 60 + parseInt(this.storedSeconds);
            }
            this.timer = setInterval(() => {
                if (this.examDuration > 0) {
                    this.examDuration--;
                    this.saveRemainingTime();
                } else {
                    this.submitExam();
                    clearInterval(this.timer);
                }
            }, 1000);
            this.isLoading = false;
        },
        saveRemainingTime() {
            const minutes = Math.floor(this.examDuration / 60);
            const seconds = this.examDuration % 60;
            this.storedMinutes = minutes;
            this.storedSeconds = seconds;
            localStorage.setItem('remainingTime', `${minutes}:${seconds}`);
        },
        // Function to shuffle an array
        shuffle(array) {
            let currentIndex = array.length, randomIndex;

            while (currentIndex !== 0) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }

            return array;
        },

    },
};
</script>
<style scoped>
.certificate-generate {
    background-color: #1976d2;
    color: white;
    padding: 8px;
    text-decoration: none;
    border-radius: 8px;
}
</style>
  