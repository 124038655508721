<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="rank" outlined v-model="form.rank" :items="ranks" label="Rank *"
                append-icon="mdi-account" :error-messages="errors.rank"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-switch v-model="form.active" inset :label="`Is Active: ${form.active == 1 ? 'YES' : 'NO'
            }`"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Given Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.last_name" outlined label="Surname Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.last_name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="dob_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.dob" label="Date Of Birth *" append-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" clearable @click:clear="dob = null"
                    :error-messages="errors.dob"></v-text-field>
                </template>
                <v-date-picker :id="`dob`" v-model="form.dob" @change="dob_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="placeofbirth" outlined v-model="form.place_of_birth" :items="birth_places"
                label="Place of Birth *" append-icon="mdi-account"
                :error-messages="errors.place_of_birth"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="nationality" outlined v-model="form.nationality" :items="nationalities"
                label="Nationality *" append-icon="mdi-account" :error-messages="errors.nationality"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="gender" outlined v-model="form.gender" :items="genders" label="gender *"
                append-icon="mdi-account" :error-messages="errors.gender"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.phone" outlined label="Mobile no *" prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.whatsapp_no" outlined label="Whatsapp no *" prepend-inner-icon="mdi-phone"
                :error-messages="errors.whatsapp_no"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.email" outlined label="Email *" prepend-inner-icon="mdi-email"
                :error-messages="errors.email"></v-text-field>
            </v-col>
            <v-col sm="4">
              <b>Upload Image</b>
              <br>
              <input type="file" id="file_0" name="file_0" ref="file_0" accept="image/*" @change="imagepath" />

              <template v-if="form.image_path != null">
                <v-img class="mt-2" v-if="form.image_path != null" :src="mediaUrl + form.image_path" alt="image 1"
                  style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_0.value = null), (form.image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="4">
              <b>Upload Signature Image</b>
              <br>
              <input type="file" id="file_7" name="file_7" ref="file_7" accept="image/*" />

              <template v-if="form.signature_image_path != null">
                <v-img class="mt-2" v-if="form.signature_image_path != null" :src="mediaUrl + form.signature_image_path"
                  alt="image 1" style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.signature_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_7.value = null), (form.signature_image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <h2>Other Details</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.passport_no" outlined label="Passport no"
                prepend-inner-icon="mdi-bank"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.cdc" outlined label="CDC *" prepend-inner-icon="mdi-bank"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.coc" outlined label="COC" prepend-inner-icon="mdi-bank"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.sid" outlined label="SID" prepend-inner-icon="mdi-buffet"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.indos" outlined label="Indos" prepend-inner-icon="mdi-buffet"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <h2>Uploads</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <b>Passpost Image <span class="error--text">*</span></b>
              <br>
              <input type="file" id="file_1" name="file_1" ref="file_1" accept="image/*" @change="PassportImagePath" />

              <template v-if="form.passport_image_path != null">
                <v-img class="mt-2" v-if="form.passport_image_path != null" :src="mediaUrl + form.passport_image_path"
                  alt="image 1" style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.passport_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_1.value = null), (form.passport_image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="4">
              <b>CDC <span class="error--text">*</span></b>
              <br>
              <input type="file" id="file_2" name="file_2" ref="file_2" accept="image/*" @change="CDCImagePath" />

              <template v-if="form.cdc_image_path != null">
                <v-img class="mt-2" v-if="form.cdc_image_path != null" :src="mediaUrl + form.cdc_image_path"
                  alt="image 1" style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.cdc_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_2.value = null), (form.cdc_image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="4">
              <b>SiD <span class="error--text">*</span></b>
              <br>
              <input type="file" id="file_3" name="file_3" ref="file_3" accept="image/*" @change="SiDImagePath" />

              <template v-if="form.sid_image_path != null">
                <v-img class="mt-2" v-if="form.sid_image_path != null" :src="mediaUrl + form.sid_image_path"
                  alt="image 1" style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.sid_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_3.value = null), (form.sid_image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="4">
              <b>COC <span class="error--text">*</span></b>
              <br>
              <input type="file" id="file_4" name="file_4" ref="file_4" accept="image/*" @change="CocImagePath" />

              <template v-if="form.coc_image_path != null">
                <v-img class="mt-2" v-if="form.coc_image_path != null" :src="mediaUrl + form.coc_image_path"
                  alt="image 1" style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.coc_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_4.value = null), (form.coc_image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="4">
              <b>WatchKeeping</b>
              <br>
              <input type="file" id="file_5" name="file_5" ref="file_5" accept="image/*"
                @change="WatchkeepingImagePath" />

              <template v-if="form.watch_keeping_image_path != null">
                <v-img class="mt-2" v-if="form.watch_keeping_image_path != null"
                  :src="mediaUrl + form.watch_keeping_image_path" alt="image 1"
                  style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.watch_keeping_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_5.value = null), (form.watch_keeping_image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="4">
              <b>COP</b>
              <br>
              <input type="file" id="file_6" name="file_6" ref="file_6" accept="image/*" @change="COPImagePath" />

              <template v-if="form.cop_image_path != null">
                <v-img class="mt-2" v-if="form.cop_image_path != null" :src="mediaUrl + form.cop_image_path"
                  alt="image 1" style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.cop_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
            ($refs.file_6.value = null), (form.cop_image_path = null)
            ">Remove</v-btn>
              </template>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col sm="4">
              <h2 style="margin-bottom: 8px;">Company Details</h2>
              <v-autocomplete outlined ref="rank" v-model="form.org_id" :items="companies" label="Select Company *"
                prepend-inner-icon="mdi-account" :error-messages="errors.companies"></v-autocomplete>
            </v-col>
            <v-col sm="4" v-if="roleName == 'ADMIN' || roleName == 'SUPER ADMIN'">
              <h2 class="text-start" style="margin-bottom: 8px;">Assign Teacher</h2>
              <v-autocomplete outlined ref="rank" v-model="form.assign_teacher_id" :items="teachers"
                label="Select Teacher *" prepend-inner-icon="mdi-account"
                :error-messages="errors.assign_teacher_id"></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12" mt="4">
              <h2>Assign Course</h2>
              <v-btn class="mt-4" @click="addRow" color="primary" small>Add Row</v-btn>
              <v-row class="mt-4" v-for="(course, index) in   courserow  " :key="index">
                <v-col sm="6" md="3" lg="3">
                  <v-autocomplete :items="courses" :disabled="course.is_assigned" label="Select Course"
                    v-model="course.course_id" :error-messages="errors[`courses.${index}.course_id`]"></v-autocomplete>
                </v-col>
                <v-col sm="6" md="3" lg="3">
                  <v-autocomplete :items="duration" label="Select Duration" v-model="course.course_duration"
                    :error-messages="errors[`courses.${index}.duration`]"></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-menu v-if="course.is_completed == true" v-model="course.start_date_menu"
                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="auto">

                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="new Date(course.created_at).toISOString().split('T')[0]" label="Issue Date"
                        prepend-icon="mdi-calendar" readonly clearable v-bind="attrs" v-on="on"
                        @click:clear="created_at = null"></v-text-field>
                    </template>
                    <v-date-picker v-model="course.created_at" @change="course.start_date_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-card-text>
              <v-divider class="mt-5 mb-5"> </v-divider>
              <v-progress-circular style="float: right;" v-if="isLoading" indeterminate
                color="primary"></v-progress-circular>
              <v-btn style="float: right;" v-else :loading="isLoading" :disable="isLoading" color="primary" dark
                @click="save">
                Update Candidate </v-btn>
              <v-btn v-if="form.is_approved == true"
                style="float: right; margin-right: 19px; font-size: 11px; width: 140px;" color="green" dark
                :loading="isLoading" :disable="isLoading">
                Approved</v-btn>
              <v-btn v-else-if="form.is_approved == false"
                style="float: right; margin-right: 19px; font-size: 11px; width: 140px;" color="green" dark
                :loading="isLoading" :disable="isLoading" @click="approved">
                Approve Candidate </v-btn>
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "UserEditPage",
  data() {
    return {
      form: {
        active: true,
      },
      companies: [],
      dob_menu: false,
      start_date_menu: false,
      roles: [
        {
          id: 0,
          text: "Select Role",
          value: "",
        },
      ],
      ranks: [],
      teachers: [],
      nationalities: [],
      birth_places: [],
      courses: [],
      genders: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      duration: [],
      subjects: [],
      image_path: "",
      passport_image_path: "",
      cdc_image_path: "",
      sid_image_path: "",
      coc_image_path: "",
      watchkeeping_image_path: "",
      cop_image_path: "",
      courserow: [],
      rows: [],
      isLoading: false,
      is_approved_profile: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    addRow() {
      // Add a new row with default values
      this.courserow.push({ course_id: null, duration: null });
    },
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;
      // Roles
      masters.ranks.forEach((rank) => {
        this.ranks.push({
          id: rank.id,
          text: rank.description,
          value: rank.description,
        })
      });
      masters.courses.forEach((course) => {
        this.courses.push({
          id: course.id,
          text: course.name,
          value: course.id,
        })
      });
      masters.companies.forEach((company) => {
        this.companies.push({
          id: company.id,
          text: company.name,
          value: company.id,
        })
      });
      masters.nationality.forEach((nationality) => {
        this.nationalities.push({
          id: nationality.id,
          text: nationality.description,
          value: nationality.description
        })
      });
      masters.birth_place.forEach((birth_place) => {
        this.birth_places.push({
          id: birth_place.id,
          text: birth_place.description,
          value: birth_place.description
        })
      });
      masters.duration.forEach((duration) => {
        this.duration.push({
          id: duration.id,
          text: duration.description,
          value: duration.description
        })
      });
      masters.subjects.forEach((subject) => {
        this.subjects.push({
          id: subject.id,
          text: subject.name,
          value: subject.id,
        })
      });
      masters.teachers.forEach((teacher) => {
        this.teachers.push({
          id: teacher.id,
          text: teacher.name,
          value: teacher.id,
        })
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.$route.params.userId}`);
      this.form = form.data.data;
      this.courserow = this.form.course_candidates
      console.log(this.form);
      this.form.roles.length > 0
        ? (this.form.role_id = this.form.roles[0].id)
        : (this.form.role_id = "");
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.isLoadingApprove = true;
        this.form.courses = this.courserow.map(row => ({
          course_id: row.course_id,
          duration: row.course_duration,
          created_at: row.created_at,
          is_completed: row.is_completed,
        }));
        let response = await axios.post("/users", this.form);
        this.form = response.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.isLoadingApprove = false;
        this.$router.push("/users");
      } catch (e) {
        this.isLoading = false;
      }
    },
    approved() {
      this.form.is_approved_profile = true;
      this.save();
    },
    imagepath(e) {
      const file = e.target.files[0];
      console.log(file);
      this.image_path = URL.createObjectURL(file);
    },
    PassportImagePath(e) {
      const file = e.target.files[0];
      this.passport_image_path = URL.createObjectURL(file);
    },
    CDCImagePath(e) {
      const file = e.target.files[0];
      this.cdc_image_path = URL.createObjectURL(file);
    },
    SiDImagePath(e) {
      const file = e.target.files[0];
      this.sid_image_path = URL.createObjectURL(file);
    },
    CocImagePath(e) {
      const file = e.target.files[0];
      this.coc_image_path = URL.createObjectURL(file);
    },
    WatchkeepingImagePath(e) {
      const file = e.target.files[0];
      this.watchkeeping_image_path = URL.createObjectURL(file);
    },
    COPImagePath(e) {
      const file = e.target.files[0];
      this.cop_image_path = URL.createObjectURL(file);
    },
    async handleFileUpload() {
      try {
        console.log(this.form);
        console.log('try uploading');
        this.isLoading = true;
        let userId = this.form.id; // Assuming you have a user ID to associate the uploads
        console.log(userId);

        let formData = new FormData();
        formData.append("id", userId);

        // Handle  image upload
        let generalImageFile = this.$refs.file_0?.files[0];
        if (generalImageFile) {
          formData.append("image_path", generalImageFile);
        }

        // Handle Signature image upload
        let signatureImageFile = this.$refs.file_7?.files[0];
        if (signatureImageFile) {
          formData.append("signature_image_path", signatureImageFile);
        }

        // Handle passport image upload
        let passportImageFile = this.$refs.file_1?.files[0];
        if (passportImageFile) {
          formData.append("passport_image_path", passportImageFile);
        }

        // Handle CDC image upload
        let cdcImageFile = this.$refs.file_2?.files[0];
        if (cdcImageFile) {
          formData.append("cdc_image_path", cdcImageFile);
        }

        // Handle SiD image upload
        let sidImageFile = this.$refs.file_3?.files[0];
        if (sidImageFile) {
          formData.append("sid_image_path", sidImageFile);
        }

        // Handle COC image upload
        let CocImageFile = this.$refs.file_4?.files[0];
        if (CocImageFile) {
          formData.append("coc_image_path", CocImageFile);
        }

        // Handle WatchKeeping image upload
        let watchkeepingImageFile = this.$refs.file_5?.files[0];
        if (watchkeepingImageFile) {
          formData.append("watch_keeping_image_path", watchkeepingImageFile);
        }

        // Handle COP image upload
        let copImageFile = this.$refs.file_6?.files[0];
        if (copImageFile) {
          formData.append("cop_image_path", copImageFile);
        }

        // Make the API call
        await axios.post("user_document_upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.isLoading = false;
      } catch (error) {
        console.error("Error uploading files:", error);
        this.isLoading = false;
      }
    },
  },
};
</script>
