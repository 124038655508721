<template>
    <v-main>
        <v-container fluid>
            <div class="mt-4" style="display: inline-flex; align-items: center;">
                <v-img alt="STA" contain :src="require('../../assets/sta.jpeg')" width="100" />
                <span class="text-h5 ml-2 font-weight-bold" v-text="'Certificate verification'"></span>
            </div>

            <v-row>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-card-text>
                            <v-row>
                                <v-col md="4">
                                    <v-text-field v-model="certificate_no" label="Certificate Number ... "
                                        prepend-inner-icon="mdi-magnify" @keydown.enter="getData"
                                        @change="page = 1"></v-text-field>
                                </v-col>

                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>
            <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
            <v-row v-else>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <tbody v-if="hasUserData">
                                    <tr>
                                        <v-col sm="4">
                                            <td>Candidate Name :{{ users.candidate.name ?
                                                users.candidate.name
                                                : "" }}</td>
                                        </v-col>
                                    </tr>
                                    <tr>
                                        <v-col sm="4">
                                            <td>Course Name :{{ users.course.name ?
                                                users.course.name
                                                : "" }}</td>
                                        </v-col>
                                    </tr>
                                    <tr>
                                        <v-col sm="4">
                                            <td>Subject :{{ users.course.subject ?
                                                users.course.subject.description
                                                : "" }}</td>
                                        </v-col>
                                    </tr>
                                    <tr>
                                        <v-col sm="4">
                                            <td>Status :{{ users.is_completed == true ?
                                                'Compelted'
                                                : 'Pending' }}</td>
                                        </v-col>
                                    </tr>
                                    <tr>
                                        <v-col sm="4">
                                            <td>
                                                Certificate :
                                                <span v-if="users.is_completed">
                                                    <a style="    background-color: #1976d2;
    color: white;
    padding: 8px;
    text-decoration: none;
    border-radius: 8px;" target="_blank" :href="`${baseUrl}/api/course_exams?certificate_no=${users.cert_no}`">
                                                        view certificate
                                                    </a>
                                                </span>
                                                <span v-else>
                                                    Pending
                                                </span>
                                            </td>
                                        </v-col>
                                    </tr>

                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="12" class="text-center">No data found</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
  
<script>
import axios from "axios";
export default {
    name: "Teacher",
    data() {
        return {
            certificate_no: "",
            users: [],
            isLoading: false,
        };
    },
    computed: {
        hasUserData() {
            return this.users && this.users.length !== 0;
        },

    },
    mounted() {
        if (this.$route.query.cert_no) {
            this.certificate_no = this.$route.query.cert_no;
            this.getData();
        }
    },
    methods: {
        async getData() {
            this.isLoading = true;
            if (!this.certificate_no.trim()) {
                this.isLoading = false;
                return;
            }
            let users = [];
            let query = "certificate_no=" + this.certificate_no;
            console.log(query);
            try {
                users = await axios.get(`course_candidates?${query}`);
                users = users.data.data;
                console.log(users.count);
                this.users = users[0];
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            this.isLoading = false;
        },
    },

};
</script>
  