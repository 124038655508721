<template>
    <v-main>
        <v-container fluid>
            <!-- partial -->
            <div class="row">
                <v-card-title class="mt-4">
                    <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
                        <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Profile
                </v-card-title>

            </div>
            <div class="row">

                <div class="col-md-4">
                    <label class="col-form-label">Name</label>
                    <div class="col-sm-12">
                        <v-text-field v-model="form.name" prepend-inner-icon="mdi mdi-account" outlined
                            clearable></v-text-field>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="col-form-label">Email</label>
                    <div class="col-sm-12">
                        <v-text-field v-model="form.email" prepend-inner-icon="mdi-email" outlined readonly></v-text-field>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="col-form-label">Phone Number</label>
                    <div class="col-sm-12">
                        <v-text-field v-model="form.phone" prepend-inner-icon="mdi-phone" outlined readonly></v-text-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label class="col-form-label">Date Of Birth</label>

                    <v-menu v-model="dob_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="form.dob" label="Date Of Birth" prepend-icon="mdi-calendar-range"
                                readonly v-bind="attrs" v-on="on" clearable @click:clear="dob = null"></v-text-field>
                        </template>
                        <v-date-picker :id="`dob`" v-model="form.dob" @change="dob_menu = false"></v-date-picker>
                    </v-menu>
                </div>
                <div class="col-md-4">
                    <label class="col-form-label">Role</label>
                    <div class="col-sm-12">
                        <v-text-field v-model="form.roles[0].name" prepend-inner-icon="mdi-account-star" outlined
                            readonly></v-text-field>
                    </div>
                </div>
            </div>
            <div class="form-group mb-0">

                <v-btn :isLoading="isLoading" color="primary" dark @click="update" style="float: right"> {{ this.updating ==
                    true ? 'Loading...' : "Update Profile" }} </v-btn>


            </div>
        </v-container>
    </v-main> <!-- partial -->
</template>
  
<script type="text/javascript">

import axios from "axios";

export default {
    name: "user",
    data() {
        return {
            form: {
                role_id: "",
                active: "",
            },
            users: [],
            roles: [],
            roleId: "",
            //   page: 1,
            count: 0,
            paginationLength: 0,
            serialNoStarting: 1,
            search_keyword: "",
            statuses: [
                {
                    id: 0,
                    text: "Select Active Status",
                    value: "",
                },
                { id: "ACTIVE", text: "ACTIVE", value: "1" },
                { id: "INACTIVE", text: "INACTIVE", value: "0" },
            ],
            isLoading: false,
            isExcelloading: false,
            updating: false
        };
    },
    mounted() {
        this.getData();
        this.getMasters();

        this.roleId = this.user.roles[0].id;
    },
    methods: {
        async getMasters() {
            let masters = await axios.get("users/masters");
            masters = masters.data;
            //roles
            masters.roles.forEach((role) => {
                this.roles.push({
                    id: role.id,
                    text: role.name,
                    value: role.id,
                });
            });
        },
        async getData() {
            this.isLoading = true;
            let form = await axios.get(
                `/users/${this.$route.params.userId}`
            );
            this.form = form.data.data;
            this.count = form.data.count;
            this.isLoading = false;
        },
        async update() {
            try {
                this.updating = true;
                await axios.patch(`/users/${this.$route.params.userId}`, this.form);
                this.updating = false; // Ensure updating is set to false in case of an error
                this.$router.push("/profile");
            } catch (e) {
                console.log(e);
                this.updating = false; // Ensure updating is set to false in case of an error
            }
        },

    },
};
</script>
  