<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add a Company
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Company Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.email" outlined label="Email *" prepend-inner-icon="mdi-email"
                :error-messages="errors.email"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.phone" outlined label="Phone *" prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.address" outlined label="Address *" prepend-inner-icon="mdi-map-marker-multiple"
                :error-messages="errors.address"></v-text-field>
            </v-col>
            <v-col sm="4">
              <b>Logo <span class="error--text">*</span></b>
              <br>
              <input type="file" id="file_1" name="file_1" ref="file_1" accept="image/*" />
              <template v-if="form.logo_image_path != null">
                <v-img class="mt-2" v-if="form.logo_image_path != null" :src="mediaUrl + form.logo_image_path"
                  alt="image 1" style="height: 50%; width: 50% !important" />
                <br>
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.logo_image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="
                  ($refs.file_1.value = null), (form.logo_image_path = null)
                  ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="4">
              <v-switch v-model="form.is_active" inset :label="`Is Active: ${form.is_active.toString() == 'true' ? 'YES' : 'NO'
                }`"></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save Company
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "CompanyCreatePage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      isLoading: false,
    };
  },

  methods: {
    async save() {
      try {
        this.isLoading = true;
        let response = await axios.post("/companies", this.form);
        this.form = response.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/companies");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      const companyId = this.form.id;
      let formData = new FormData();
      formData.append("id", companyId);
      let logo_image_path = this.$refs.file_1?.files[0];
      formData.append("logo_image_path", logo_image_path);
      await axios
        .post("company_logo_upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
