<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item v-if="roleName != 'CANDIDATE' && roleName != 'COMPANY'" class="my-4 mx-2 px-16">
        <router-link to="/">
          <v-img alt="STA" contain :src="require('../../assets/sta.jpeg')" width="100" />
        </router-link>
      </v-list-item>
      <v-list-item v-else class="my-4 mx-2 px-16">
        <router-link to="/profile">
          <v-img alt="STA" contain :src="require('../../assets/sta.jpeg')" width="100" />
        </router-link>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item v-ripple="{ class: 'primary--text' }" :to="`/profile`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-account </v-icon>
          </v-list-item-icon>

          <v-list-item-title class="text-subtitle-1">My Profile</v-list-item-title>
        </v-list-item>
        <!-- Companies -->
        <!-- <v-list-item v-ripple="{ class: 'primary--text' }" v-if="roleName == 'ADMIN' || roleName == 'SUPER ADMIN'"
          :to="`/companies`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-factory </v-icon>
          </v-list-item-icon>

          <v-list-item-title class="text-subtitle-1">Companies</v-list-item-title>
        </v-list-item> -->

        <!-- Users -->
        <v-list-group no-action :value="false"
          v-if="roleName == 'ADMIN' || roleName == 'SUPER ADMIN' || roleName == 'TEACHER'">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25"> mdi-calendar-account </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Data</v-list-item-title>
          </template>

          <v-list-item :to="`/users`" class="v-list-item">
            <v-list-item-icon>
              <v-icon size="25"> mdi-account-group </v-icon>
            </v-list-item-icon>

            <v-list-item-title class="text-subtitle-1">Candidates</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/teacher`" class="v-list-item" v-if="roleName == 'ADMIN' || roleName == 'SUPER ADMIN'">
            <v-list-item-icon>
              <v-icon size="25"> mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-title class="text-subtitle-1">Teachers</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/admin`" class="v-list-item" v-if="roleName == 'SUPER ADMIN'">
            <v-list-item-icon>
              <v-icon size="25"> mdi-account-badge </v-icon>
            </v-list-item-icon>

            <v-list-item-title class="text-subtitle-1">Admin</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/company`" class="v-list-item" v-if="roleName == 'ADMIN' || roleName == 'SUPER ADMIN'">
            <v-list-item-icon>
              <v-icon size="25"> mdi-domain </v-icon>
            </v-list-item-icon>

            <v-list-item-title class="text-subtitle-1">Company</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/certificates`" class="v-list-item" v-if="roleName == 'ADMIN' || roleName == 'SUPER ADMIN' || roleName == 'TEACHER'">
            <v-list-item-icon>
              <v-icon size="25"> mdi-certificate </v-icon>
            </v-list-item-icon>

            <v-list-item-title class="text-subtitle-1">Certificate</v-list-item-title>
          </v-list-item>

        </v-list-group>
        <v-list-item v-ripple="{ class: 'primary--text' }" :to="`/course`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-book </v-icon>
          </v-list-item-icon>

          <v-list-item-title class="text-subtitle-1">Courses</v-list-item-title>
        </v-list-item>
        <!-- Users -->
        <!-- <v-list-item :to="`/geofence/create`">
            <v-list-item-icon>
              <v-icon size="25"> mdi-shape </v-icon>
            </v-list-item-icon>
            <v-list-item-title>GeoFence</v-list-item-title>
          </v-list-item> -->
        <!-- Reports -->
        <!-- Settings -->
        <v-list-group no-action :value="false"
          v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN' || roleName == 'TEACHER'">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size=" 25">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Settings</v-list-item-title>
          </template>

          <v-list-item :to="`/values`">
            <v-list-item-icon>
              <v-icon size="25"> mdi-shape </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Values</v-list-item-title>
          </v-list-item>

          <v-list-item :to="`/value-lists`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Value Lists</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/orgs`" class="v-list-item" v-if="roleName == 'ADMIN' || roleName == 'SUPER ADMIN'">
            <v-list-item-icon>
              <v-icon size="25"> mdi-account-file
              </v-icon>
            </v-list-item-icon>

            <v-list-item-title class="text-subtitle-1">Org</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="primary" block color="white" text @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn color="black" small text>
        {{ user.name != null ? user.name : user.email }}
        [ {{ roleName }}]
      </v-btn>
    </v-app-bar>

    <router-view />
  </div>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      companyName: "",
      companyId: "",
    };
  },
  mounted() {

  },
  components: {},
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
      location.replace("/");
    },
  },
};
</script>
<style scoped>
.nav-text {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action>.v-list-group__items>.v-list-item {
  padding: 0 8px 0 20px;
}
</style>
