<template>
  <v-main>
    <v-row>
      <v-card-title class="mt-4">
        <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        {{ courseName }} Course | {{ candidateName }}
      </v-card-title>
      <v-col md="12">
        <v-card elevation="6">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Sr</th>
                  <th class="text-center">Course name</th>
                  <th class="text-center">Marks</th>
                  <th class="text-center">Percentage</th>
                  <th class="text-center">Attempt No</th>
                  <th style="width: 18%" class="text-center">Status</th>
                </tr>
              </thead>
              <tbody v-if="candidate_performance.length !== 0">
                <tr v-for="(candidate, i) in candidate_performance" :key="`candidate${i}`">
                  <td style="min-width: 85px">
                    <v-tooltip bottom v-if="candidate.is_active === 1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small dark color="green">
                          mdi-circle
                        </v-icon>
                      </template>
                      <span>User is Active</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small dark color="red">
                          mdi-circle
                        </v-icon>
                      </template>
                      <span>User is InActive</span>
                    </v-tooltip>
                    &nbsp;
                    {{ i + 1 }}
                  </td>
                  <td class="text-center">{{ candidate.course.name || "" }}</td>
                  <td class="text-center">
                    {{ candidate.total_marks }}
                  </td>
                  <td class="text-center">
                    {{
                      candidate.total_percentage
                    }}%
                  </td>
                  <td class="text-center">
                    {{ candidate.attempt_no ? candidate.attempt_no : "" }}
                  </td>
                  <td class="text-center" style="display: flex;">
                    <v-img v-if="candidate.total_percentage >=
                      candidate.course.passing_marks
                      " alt="result" contain :src="require('../../assets/pass.png')" width="41" />

                    <v-img v-else alt="result" contain :src="require('../../assets/fail.png')" width="41" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="8" class="text-center">No data found</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "QuestionPage",
  data() {
    return {
      candidate_performance: [],
      courseName: '',
      candidateName: ''
    };
  },
  created() {
    this.course_id = this.$route.query.course_id;
    this.candidate_id = this.$route.query.candidate_id;
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let query =
        "course_id=" + this.course_id + "&candidate_id=" + this.candidate_id;
      let candidate_performance = await axios.get(`/course_exams?${query}`);
      this.candidate_performance = candidate_performance.data.data;
      this.courseName = this.candidate_performance[0].course.name;
      this.candidateName = this.candidate_performance[0].candidate.name;
      console.log(this.candidate_performance);
    },
  },
};
</script>
