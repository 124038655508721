<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Company
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Company Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.email" outlined label="Email *" prepend-inner-icon="mdi-email"
                :error-messages="errors.email"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.contact_person" outlined label="Contact Person *"
                prepend-inner-icon="mdi-card-account-phone" :error-messages="errors.contact_person"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.phone" outlined label="Phone *" prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.address" outlined label="Address *" prepend-inner-icon="mdi-map-marker-multiple"
                :error-messages="errors.address"></v-text-field>
            </v-col>
            <v-row>

              <v-col sm="4">
                <b>Logo <span class="error--text">*</span></b>
                <br>
                <input type="file" id="file_1" name="file_1" ref="file_1" accept="image/*" />
                <template v-if="form.logo_image_path != null">
                  <v-img class="mt-2" v-if="form.logo_image_path != null" :src="mediaUrl + form.logo_image_path"
                    alt="image 1" style="height: 50%; width: 50% !important" />
                  <br>
                  <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.logo_image_path}`" target="_blank"
                    color="primary">View</v-btn>
                  <v-btn x-small class="reset_image my-1" color="red" dark @click="
                    ($refs.file_1.value = null), (form.logo_image_path = null)
                    ">Remove</v-btn>
                </template>
              </v-col>
              <v-col sm="4">
                <b>Logo 2 <span class="error--text">*</span></b>
                <br>
                <input type="file" id="file_2" name="file_2" ref="file_2" accept="image/*" />
                <template v-if="form.logo_image_path2 != null">
                  <v-img class="mt-2" v-if="form.logo_image_path2 != null" :src="mediaUrl + form.logo_image_path2"
                    alt="image 2" style="height: 50%; width: 50% !important" />
                  <br>
                  <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.logo_image_path2}`" target="_blank"
                    color="primary">View</v-btn>
                  <v-btn x-small class="reset_image my-1" color="red" dark @click="
                    ($refs.file_2.value = null), (form.logo_image_path2 = null)
                    ">Remove</v-btn>
                </template>
              </v-col>
              <v-col sm="4">
                <b>Logo 3 <span class="error--text">*</span></b>
                <br>
                <input type="file" id="file_3" name="file_3" ref="file_3" accept="image/*" />
                <template v-if="form.logo_image_path3 != null">
                  <v-img class="mt-2" v-if="form.logo_image_path3 != null" :src="mediaUrl + form.logo_image_path3"
                    alt="image 3" style="height: 50%; width: 50% !important" />
                  <br>
                  <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.logo_image_path3}`" target="_blank"
                    color="primary">View</v-btn>
                  <v-btn x-small class="reset_image my-1" color="red" dark @click="
                    ($refs.file_3.value = null), (form.logo_image_path3 = null)
                    ">Remove</v-btn>
                </template>
              </v-col>
              <v-col sm="4">
                <b>Qr Image<span class="error--text">*</span></b>
                <br>
                <input type="file" id="file_4" name="file_4" ref="file_4" accept="image/*" />
                <template v-if="form.qr_code_image_path != null">
                  <v-img class="mt-2" v-if="form.qr_code_image_path != null" :src="mediaUrl + form.qr_code_image_path"
                    alt="image 4" style="height: 50%; width: 50% !important" />
                  <br>
                  <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.qr_code_image_path}`" target="_blank"
                    color="primary">View</v-btn>
                  <v-btn x-small class="reset_image my-1" color="red" dark @click="
                    ($refs.file_4.value = null), (form.qr_code_image_path = null)
                    ">Remove</v-btn>
                </template>
              </v-col>
              <v-col sm="4">
                <b>Principle Signature Image<span class="error--text">*</span></b>
                <br>
                <input type="file" id="file_5" name="file_5" ref="file_5" accept="image/*" />
                <template v-if="form.principle_signature_path != null">
                  <v-img class="mt-2" v-if="form.principle_signature_path != null"
                    :src="mediaUrl + form.principle_signature_path" alt="image 4"
                    style="height: 50%; width: 50% !important" />
                  <br>
                  <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.principle_signature_path}`" target="_blank"
                    color="primary">View</v-btn>
                  <v-btn x-small class="reset_image my-1" color="red" dark @click="
                    ($refs.file_5.value = null), (form.principle_signature_path = null)
                    ">Remove</v-btn>
                </template>
              </v-col>
            </v-row>
            <v-col sm="4">
              <v-switch v-model="form.is_active" inset :label="`Is Active: ${form.is_active == 1 ? 'YES' : 'NO'
                }`"></v-switch>
            </v-col>
          </v-row>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Update Company
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "CompanyEditPage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/companies/${this.$route.params.companyId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        let response = await axios.patch(
          `/companies/${this.$route.params.companyId}`,
          this.form
        );
        this.form = response.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/companies");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      const companyId = this.form.id;
      let formData = new FormData();
      formData.append("id", companyId);
      let logo_image_path = this.$refs.file_1?.files[0];
      formData.append("logo_image_path", logo_image_path);
      let logo_image_path2 = this.$refs.file_2?.files[0];
      formData.append("logo_image_path2", logo_image_path2);
      let logo_image_path3 = this.$refs.file_3?.files[0];
      formData.append("logo_image_path3", logo_image_path3);
      let qr_code_image_path = this.$refs.file_4?.files[0];
      formData.append("qr_code_image_path", qr_code_image_path);
      let principle_signature_path = this.$refs.file_5?.files[0];
      formData.append("principle_signature_path", principle_signature_path);
      await axios
        .post("company_logo_upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
