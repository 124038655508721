<template>
  <v-main>

    <v-container fluid>
      <v-row justify="center" class="mt-4">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Courses'"></span>
          <v-btn v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN' || roleName == 'TEACHER'" class="mx-2 mb-2" fab
            x-small color="primary" to="/course/create">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row v-if="roleName != 'CANDIDATE'">
                <v-col md="6">
                  <v-text-field v-model="search_keyword" label="Search ... " prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData" @change="page = 1"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="created_by" v-model="created_by_id" :items="created_by" label="Created By"
                    @change="page = 1, getData()" prepend-icon="mdi-account" clearable></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <v-autocomplete ref="status" v-model="is_active" v-if="roleName !== 'CANDIDATE'" :items="statuses"
                    label="Status" @change="page = 1, getData()" prepend-icon="mdi-toggle-switch"
                    clearable></v-autocomplete>
                  <v-autocomplete ref="status" v-model="course_status" v-else :items="candidate_status" label="Status"
                    @change="page = 1, getData()" prepend-icon="mdi-toggle-switch" clearable></v-autocomplete>
                </v-col>


                <v-col md="3">
                  <v-pagination v-model="page" circle :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">

            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Course Name</th>
                    <th class="text-left">Subject</th>
                    <th class="text-left">Passing Marks</th>
                    <th class="text-left">Attempts</th>
                    <th v-if="roleName == 'CANDIDATE'" class="text-left">Video Count</th>
                    <th v-if="roleName == 'CANDIDATE'" class="text-left">Document Count</th>
                    <th v-if="roleName == 'CANDIDATE'" class="text-left">Description Count</th>
                    <th v-if="roleName == 'CANDIDATE'" class="text-left">Attempts Done</th>
                    <th class="text-left">Exam Duration</th>
                    <th style="width:18%;" class="text-center"
                      v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN' || roleName == 'TEACHER'">
                      Action
                    </th>
                    <th style="width:14%;" class="text-center" v-else>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="courses.length != 0 && roleName == 'SUPER ADMIN' || roleName == 'ADMIN' || roleName == 'TEACHER' || roleName == 'COMPANY'">
                  <tr v-for="(course, i) in  courses " :key="`course${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="course.is_active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="green">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>course is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="course.is_active == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="red">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>course is InActive</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td class="text-left">{{ course.name || "" }}</td>
                    <td class="text-left">{{ course.subject ? course.subject.description : "" }}</td>
                    <td class="text-left">{{ course.passing_marks ? course.passing_marks : "" }}</td>
                    <td class="text-left">{{ course.no_of_attempt ? course.no_of_attempt : "" }}</td>
                    <td class="text-left">{{ course.exam_duration ? course.exam_duration : "" }}</td>
                    <td
                      v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN' || roleName == 'TEACHER' || roleName == 'COMPANY'">
                      <div v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN' || roleName == 'TEACHER'"
                        style="display: flex; justify-content: center;">
                        <v-btn class="mx-2" fab x-small color="primary" :to="`/course/${course.id}`">
                          <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-1" color="black" x-small dark fab v-bind="attrs" v-on="on"
                              :to="{ path: `/question/course/${course.id}`, query: { course_name: course.name } }">
                              <v-icon dark>mdi-folder-question</v-icon>
                            </v-btn>
                          </template>
                          <span>Questions</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="warning" x-small fab dark v-bind="attrs" v-on="on"
                              :to="{ path: `/course/candidates/${course.id}`, query: { course_name: course.name } }">
                              <v-icon>mdi-account-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Candidates</span>
                        </v-tooltip>
                        <!-- <v-btn class="mx-2" fab x-small color="error" @click="Delete_this(course.id)">
                          <v-icon dark> mdi-delete </v-icon>
                        </v-btn> -->
                      </div>
                      <div v-if="roleName == 'COMPANY'" style="display: flex; justify-content: center;">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="warning" x-small fab dark v-bind="attrs" v-on="on"
                              :to="{ path: `/course/candidates/${course.id}`, query: { course_name: course.name } }">
                              <v-icon>mdi-account-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Candidates</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="courses.length != 0 && roleName == 'CANDIDATE'">
                  <tr v-for="( course, i ) in  form " :key="`course${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="course.is_active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="green">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>course is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="course.active == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="red">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>course is InActive</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td class="text-left">{{ course.course.name || "" }}</td>
                    <td class="text-left">{{ course.course.subject ? course.course.subject.description : "" }}
                    </td>
                    <td class="text-left">{{ course.course.passing_marks ? course.course.passing_marks :
                      "" }}</td>
                    <td class="text-left">{{ course.course.no_of_attempt ? course.course.no_of_attempt :
                      "" }}</td>
                    <td class="text-left">{{ course.course_view_count ? course.course_view_count.video_count
                      :
                      "" }}</td>
                    <td class="text-left">{{ course.course_view_count ?
                      course.course_view_count.document_count :
                      "" }}</td>
                    <td class="text-left">{{ course.course_view_count ? course.course_view_count.text_count :
                      "" }}</td>
                    <td class="text-left">{{ course.total_no_of_attempts ? course.total_no_of_attempts :
                      "" }}</td>
                    <td class="text-left">{{ course.course.exam_duration ? course.course.exam_duration :
                      "" }}</td>
                    <td class="text-right"
                      v-if="roleName == 'CANDIDATE' && course.is_expired == false && course.is_completed == false">
                      <v-btn class="mx-2" style="padding: 0 10px;font-size: 11px; " color="primary"
                        :to="`/course/candidate/${course.course.id}`">
                        View Course
                      </v-btn>
                    </td>
                    <td class="text-left" style="display: flex; align-items: center;"
                      v-else-if="roleName == 'CANDIDATE' && course.is_completed == true">
                      <v-col sm="4">
                        <a target="_blank" :href="`${baseUrl}/api/course_exams?certificate_no=${course.cert_no}`"
                          style="position: absolute;">
                          Certificate
                        </a>
                      </v-col>
                      <v-col sm="4">
                        <v-btn class="mx-2" style="padding: 0px 15px;font-size: 11px;" color="primary">Completed</v-btn>
                      </v-col>
                    </td>
                    <td class="text-left" v-else>
                      <v-btn class="mx-2" style="padding: 0px 25px;font-size: 11px;" color="red"
                        @click="showExpiredDialog">
                        Expired
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="8" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="expiredDialog" max-width="400">
        <v-card>
          <v-card-title class="headline red--text">
            Course Expired
          </v-card-title>
          <v-card-text>
            This course has expired. Please contact admin for assistance.
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="expiredDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "CoursesPage",
  data() {
    return {
      form: {
        roles: []
      },
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      candidate_status: [
        {
          id: 0,
          text: "Select Course Status",
          value: "",
        },
        { id: "COMPLETED", text: "COMPLETED", value: "COMPLETED" },
        { id: "EXPIRED", text: "EXPIRED", value: "EXPIRED" },
      ],
      courses: [],
      Subjects: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      isLoading: false,
      is_expired: false,
      expiredDialog: false,
      created_by: [],
      created_by_id: "",
      is_active: "",
      company_user_id: "",
      course_status: ""

    };
  },
  watch: {
    page: "getData",
  },

  mounted() {
    this.disableBackButton();
    if (this.roleName == 'CANDIDATE') {
      this.candidate_id = this.user.id;
    }
    if (this.roleName == 'COMPANY') {
      this.company_user_id = this.user.id;
    }
    this.getData();
    this.getMasters();
  },
  beforeDestroy() {
    // Re-enable the back button functionality when the component is destroyed
    this.enableBackButton();
  },
  methods: {
    showExpiredDialog() {
      this.expiredDialog = true;
    },
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      masters.subjects.forEach((Subject) => {
        this.Subjects.push({
          id: Subject.id,
          text: Subject.name,
          value: Subject.id,
        });
      });
      masters.created_by.forEach((created_by) => {
        this.created_by.push({
          id: created_by.id,
          text: created_by.name,
          value: created_by.id,
        });
      });
    },
    async getData() {
      this.form.subject_id = this.form.subject_id ? this.form.subject_id : "";
      this.candidate_id = this.candidate_id ? this.candidate_id : "";
      this.is_active = this.is_active ? this.is_active : "";
      this.created_by_id = this.created_by_id ? this.created_by_id : "";
      this.course_status = this.course_status ? this.course_status : "";
      this.company_user_id = this.company_user_id ? this.company_user_id : "";

      let query =
        "page=" +
        this.page +
        "&subject_id=" +
        this.form.subject_id +
        "&active=" +
        this.is_active +
        "&search_keyword=" +
        this.search_keyword +
        "&candidate_id=" +
        this.candidate_id +
        "&company_user_id=" +
        this.company_user_id +
        "&created_by_id=" +
        this.created_by_id +
        "&is_completed=" +
        (this.course_status === "COMPLETED" ? "1" : "") + // include is_completed based on selected status
        "&is_expired=" +
        (this.course_status === "EXPIRED" ? "1" : "") +
        "&rowsPerPage=" +
        this.rowsPerPage;
      if (this.roleName == 'CANDIDATE') {
        this.isLoading = true;
        let courses = await axios.get(`/course_candidates?${query}`);
        this.isLoading = false;
        this.form = courses.data.data
        this.form.forEach(course => {
          if (course.is_expired == true) {
            this.is_expired = true
          } else {
            this.is_expired = false
          }
        });
        this.courses = courses.data.data.map(item => item.course);
        console.log(this.courses);
        this.count = courses.data.count;
      } else {
        this.isLoading = true;
        let courses = await axios.get(`/course?${query}`);
        this.isLoading = false;
        this.form = courses.data.data
        this.courses = courses.data.data
        this.count = courses.data.count;

      }
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
    },

    disableBackButton() {
      window.history.pushState(null, document.title, window.location.href);

      window.onpopstate = function () {
        window.history.pushState(null, document.title, window.location.href);
      };
    },
    enableBackButton() {
      window.onpopstate = null;
    },
    async Delete_this(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(`course/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
