import Vue from "vue";
import moment from "moment";

Vue.filter("hhmm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value, ["HH:mm:ss"]).format("hh:mm A");
});

Vue.filter("ddmmyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("DD-MM-YYYY");
});
Vue.filter("getExtension", function (path = "") {
  if (path) {
    let path_array = path.split("/");
    let image_name = path_array[3];
    let extension = image_name?.split(".")[1].toUpperCase();
    return extension;
  }
})