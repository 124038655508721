<template>
  <v-main>
    <v-container fluid>
      <div>
        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/questions"></back-button>
            Upload Questions
          </h5>
        </div>

      </div>
      <div>
        <v-card elevation="6">
          <v-btn text>
            <a :href="`${path}sta/samples/STA_course_exam_questions_16_12_2023_v3.0.xlsx`">Download sample file</a>
          </v-btn>
          <v-card-title>
            <!-- <backbutton></backbutton> -->
            Upload Question Data
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="crude_questions.length == 0">
                <div v-if="isLoading == true" style="
                    color: green;
                    font-weight: bold;
                    font-size: 16px;
                    margin-top: 10px;
                  ">
                  Please Wait... File loading.
                </div>
                <input type="file" id="file" name="file" ref="file" accept="application/ms-excel" multiple
                  @input="handleFileUpload" />
                <div v-if="isUploading" style="
                    color: green;
                    font-weight: bold;
                    font-size: 16px;
                    margin-top: 10px;
                  ">
                  Uploading file... Please wait.
                </div>
              </v-col>
              <v-col v-else>
                <v-btn color="success" :loading="isProcessing" :disabled="isProcessing" @click="processFile">
                  Process file</v-btn>

                &nbsp;
                <v-btn color="error" @click="truncatePendingDocuments">
                  Delete</v-btn>
                <div v-if="isProcess" style="
                    color: #007bff;
                    font-weight: bold;
                    font-size: 16px;
                    margin-top: 10px;
                  ">
                  Processing file... Please wait.
                </div>
                <div v-if="is_Truncated" style="
                    color: #007bff;
                    font-weight: bold;
                    font-size: 16px;
                    margin-top: 10px;
                  ">
                  Deleted file... .
                </div>
              </v-col>
            </v-row>
            <v-row>
              <span style="color: red" v-show="isError">{{ error_msg }} </span>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <br />
      <br />
      <div>
        <v-card elevation="6">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Sr.No</th>
                  <th class="text-center">Course Name</th>
                  <th class="text-center">Question</th>
                  <th class="text-center">Option 1</th>
                  <th class="text-center">Option 2</th>
                  <th class="text-center">Option 3</th>
                  <th class="text-center">Option 4</th>
                  <th class="text-center">Correct Option</th>
                  <th class="text-center">Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(question, at) in crude_questions" :key="`question${at}`">
                  <td class="text-center">{{ at + 1 }}</td>
                  <td class="text-center">{{ question.course_name }}</td>
                  <td class="text-center">{{ question.question }}</td>
                  <td class="text-center">{{ question.option_1 }}</td>
                  <td class="text-center">{{ question.option_2 }}</td>
                  <td class="text-center">{{ question.option_3 }}</td>
                  <td class="text-center">{{ question.option_4 }}</td>
                  <td class="text-center">{{ question.correct_answer }}</td>
                  <td class="text-center">{{ question.marks }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
// import backbutton from "@/components/backbutton.vue";
export default {
  //   components: { backbutton },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Upload User Excel",
  },
  data() {
    return {
      crude_questions: [],
      isLoading: false,
      is_mail_sent: false,
      isUploading: false,
      is_Uploaded: false,
      isProcessing: false,
      isProcess: false,
      is_Processed: false,
      is_Truncated: false,
      isError: false,
      hardrefresh: false,
      error_msg: "",
      path: "",
    };
  },
  mounted() {
    this.getData();
    this.path = this.mediaUrl;
    console.log("mounted first");
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crude_questions = await axios.get(`/crude_questions`);
      this.crude_questions = crude_questions.data.data;
      console.log(this.crude_questions);
      this.count = crude_questions.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isUploading = true;
      this.isUploadingError = false;
      this.error_msg = "";
      this.questions = this.$refs.file.files[0];
      const courseId = this.$route.params.courseId;
      let formData = new FormData();
      formData.append("questions", this.questions);
      formData.append("courseId", courseId); // Append courseId to formData
      this.changeValue = 100;
      localStorage.setItem("isFileUploaded", "true");

      await axios
        .post("upload_questions", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crude_questions = response.data.data;
          this.isUploading = false; // Hide loading message
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
    },
    async processFile() {
      try {
        this.isProcess = true;
        this.isProcessing = true;
        await axios
          .get(`process_questions`)
          .then(() => {
            this.isProcess = false;
          })
          .catch(() => {
            this.isError = true;
            this.error_msg =
              "Looks like system faced an error while processing the File. Kindly check the file before processing ...";
          });
        await axios.get("truncate_questions");
        this.crude_products = [];
        this.isProcessing = false;
        this.is_Processed = true;
        alert("Processed Successfully");
        this.getData();
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      this.is_Truncated = true;
      await axios.get("truncate_questions");
      alert("Truncated Successfully");
      this.is_Truncated = false;
      this.getData();

      this.crude_products = [];
    },
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
td {
  min-width: 130px !important;
}
</style>
