<template>
    <v-main>
        <v-container fluid>
            <v-card elevation="6">
                <v-card-title class="mt-4">
                    <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
                        <v-icon dark> mdi-arrow-left </v-icon> </v-btn>
                    Edit {{ $route.query.course_name }} Questions
                </v-card-title>
                <v-card-text>
                    <v-row class="mb-2" style="padding: 12px;">
                        <v-col>
                            <vue-editor v-model="form.question" class="form-control" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field v-model="form.option_1" outlined label="Option 1 *"
                                prepend-inner-icon="mdi-pencil" :error-messages="errors.option_1"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.option_2" outlined label="Option 2 *"
                                prepend-inner-icon="mdi-pencil" :error-messages="errors.option_2"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.option_3" outlined label="Option 3 *"
                                prepend-inner-icon="mdi-pencil" :error-messages="errors.option_3"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.option_4" outlined label="Option 4*" prepend-inner-icon="mdi-pencil"
                                :error-messages="errors.option_4"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.correct_answer" outlined label="Correct Answer *"
                                prepend-inner-icon="mdi-email" :error-messages="errors.correct_answer"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.marks" outlined label="Marks *" prepend-inner-icon="mdi-email"
                                :error-messages="errors.marks"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-5 mb-5"> </v-divider>
                    <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
                    <v-btn v-else color="primary" dark @click="update()"> Update Question </v-btn>
                </v-card-text>
            </v-card>
        </v-container>
    </v-main>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
    name: "TeacherCreate",
    components: {
        VueEditor
    },
    data() {
        return {
            config: {
                events: {
                    initialized: function () {
                        console.log("initialized");
                    },
                },
                placeholderText: "Add Question!",
                charCounterCount: true,
                videoMaxSize: 100 * 1024 * 1024,
                wordDeniedAttrs: ["style"],
                wordPasteModal: false,
                wordPasteKeepFormatting: false,
                key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
            },
            form: {
                active: true,
            },
            companyId: "",
            doj_menu: false,
            isLoading: false,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            console.log('user');
            this.isLoading = true;
            let form = await axios.get(`/course_questions/${this.$route.params.questionId}`);
            this.form = form.data.data;
            // console.log(this.form.question);
            this.isLoading = false;
        },
        async update() {
            console.log('entered');
            try {
                this.isLoading = true;
                this.form.course_id = this.$route.params.courseId;
                console.log(this.form.course_id);
                await axios.patch(`/course_questions/${this.$route.params.questionId}`, this.form);
                this.isLoading = false;
                this.$router.push("/course");
            } catch (e) {
                // alert("error");
                this.isLoading = false;
            }
        },
    },
};
</script>
  