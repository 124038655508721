<template>
    <v-main>
        <v-container fluid>
            <v-row justify="center" class="mt-4">
                <v-col>
                    <span class="text-h5 font-weight-bold" v-text="'Orgs'"></span>
                    <v-btn class="mx-2 mb-2" fab x-small color="primary" to="/org/create">
                        <v-icon dark> mdi-plus </v-icon>
                    </v-btn>

                </v-col>
            </v-row>

            <v-row>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-card-text>
                            <v-row>
                                <v-col md="4">
                                    <v-text-field v-model="search_keyword" label="Search ... "
                                        prepend-inner-icon="mdi-magnify" @keydown.enter="getData"
                                        @change="page = 1"></v-text-field>
                                </v-col>
                                <v-col md="3">
                                    <v-pagination v-model="page" circle :length="paginationLength">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
            <v-row v-else>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-card-title v-if="orgs.length != 0">
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Sr No</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="orgs.length != 0">
                                    <tr v-for="(org, i) in orgs" :key="`org${i}`">
                                        <td style="min-width: 85px">

                                            {{ i + 1 }}
                                        </td>
                                        <td>{{ org.name || "" }}</td>

                                        <td class="text-center">
                                            <v-btn class="mx-2" fab x-small color="primary" :to="`/orgs/${org.id}`">
                                                <v-icon dark> mdi-pencil </v-icon>
                                            </v-btn>
                                            <!-- <v-btn class="mx-2" fab x-small color="error" @click="Delete_this(user.id)">
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn> -->
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="12" class="text-center">No data found</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
    
<script>
import axios from "axios";
export default {
    name: "OrgPage",
    data() {
        return {
            form: {

            },
            page: 1,
            serialNoStarting: 1,
            paginationLength: 0,
            count: 0,
            search_keyword: "",
            orgs: [],
            isLoading: false

        };
    },
    watch: {
        page: "getData",
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            console.log(this.form.supervisor_id);
            this.isLoading = true;
            this.search_keyword = this.search_keyword ? this.search_keyword : "";
            console.log(this.search_keyword);
            let orgs = [];
            let query =
                "page=" +
                this.page +
                "&rowsPerPage=" +
                this.rowsPerPage +
                "&search_keyword=" +
                this.search_keyword;
            orgs = await axios.get(`orgs?${query}`);
            this.orgs = orgs.data.data;
            this.count = orgs.data.count;
            console.log(this.count);
            this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
            this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
            this.isLoading = false;
        },

        async Delete_this(id) {
            let check = confirm("Are you sure you want to delete the data");
            if (check) {
                try {
                    this.isLoading = true;
                    await axios.delete(`orgs/${id}`);
                    this.isLoading = false;
                    this.getData();
                } catch (e) {
                    this.isLoading = false;
                }
            }
        },

    },
};
</script>
    