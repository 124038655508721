import { mapGetters } from 'vuex'
import Vue from 'vue'

const mixin = {
  install (Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters({
          authenticated: 'auth/authenticated',
          user: 'auth/user',
          company: 'auth/company',
          errors: 'errors/getErrors',
          status: 'errors/getStatus',
          baseUrl: 'settings/getBaseUrl',
          mediaUrl: 'settings/getMediaUrl',
          rowsPerPage: 'settings/getRowsPerPage',
          roleName: 'auth/roleName',
        })
      }
    })
  }
}

Vue.use(mixin)