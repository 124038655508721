<template>
    <v-main>
        <v-container fluid>
            <v-row justify="center" class="mt-4">
                <v-col>
                    <span class="text-h5 font-weight-bold" v-text="'My Profile'"></span>
                </v-col>
            </v-row>

            <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
            <v-row v-else>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Sr No</th>
                                        <th class="text-left">Role</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Email</th>
                                        <th class="text-left">Phone</th>
                                        <th class="text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="users.length != 0">
                                    <tr v-for="(user, i) in users" :key="`user${i}`">
                                        <td style="min-width: 85px">
                                            <v-tooltip bottom v-if="user.active == 1">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" small dark color="green">
                                                        mdi-circle
                                                    </v-icon>
                                                </template>
                                                <span>User is Active</span>
                                            </v-tooltip>
                                            <v-tooltip bottom v-if="user.active == 0">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" small dark color="red">
                                                        mdi-circle
                                                    </v-icon>
                                                </template>
                                                <span>User is InActive</span>
                                            </v-tooltip>
                                            &nbsp;
                                            {{ i + 1 }}
                                        </td>
                                        <td>{{ user.roles[0] ? user.roles[0].name : "" }}</td>
                                        <td>{{ user.name || "" }}</td>
                                        <td>{{ user.email || "" }}</td>
                                        <td>{{ user.phone || "" }}</td>
                                        <td class="text-right">
                                            <v-btn v-if="roleName == 'CANDIDATE'" class="mx-2" fab x-small color="primary"
                                                :to="`/candidate_profile/${user.id}`">
                                                <v-icon dark> mdi-pencil </v-icon>
                                            </v-btn>
                                            <v-btn v-else class="mx-2" fab x-small color="primary"
                                                :to="`/profile/${user.id}`">
                                                <v-icon dark> mdi-pencil </v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="12" class="text-center">No data found</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
import axios from "axios";
export default {
    name: "ProfilePage",
    data() {
        return {
            form: {
                active: "",
                role_id: "",
            },
            users: [],
            isLoading: false,
        };
    },
    watch: {
        page: "getData",
    },
    mounted() {
        this.disableBackButton();
        this.getData();
        this.getMasters();
    },
    beforeDestroy() {
        // Re-enable the back button functionality when the component is destroyed
        this.enableBackButton();
    },
    methods: {
        disableBackButton() {
            window.history.pushState(null, document.title, window.location.href);

            window.onpopstate = function () {
                window.history.pushState(null, document.title, window.location.href);
            };
        },
        enableBackButton() {
            window.onpopstate = null;
        },
        async getMasters() {
            let masters = await axios.get(`users/masters`);
            masters = masters.data;
            // Roles
            masters.teachers.forEach((teacher) => {
                this.Teachers.push({
                    id: teacher.id,
                    text: teacher.name,
                    value: teacher.id,
                });
            });

        },
        async getData() {
            this.isLoading = true;
            let users = [];
            let query =
                "&user_id=" +
                this.user.id;
            users = await axios.get(`users?${query}`);
            this.users = users.data.data;
            this.isLoading = false;
        },
    },
};
</script>
  