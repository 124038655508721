<template>
    <v-main>
        <v-container fluid>
            <v-card elevation="6">
                <v-card-title class="mt-4">
                    <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
                        <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Teacher
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field v-model="form.name" outlined label="First Name *" prepend-inner-icon="mdi-buffet"
                                :error-messages="errors.name"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.middle_name" outlined label="Middle Name *"
                                prepend-inner-icon="mdi-buffet" :error-messages="errors.middle_name"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.last_name" outlined label="Last Name *"
                                prepend-inner-icon="mdi-buffet" :error-messages="errors.last_name"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.phone" outlined label="Mobile no *" prepend-inner-icon="mdi-phone"
                                :error-messages="errors.phone"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field v-model="form.email" outlined label="Email *" prepend-inner-icon="mdi-email"
                                :error-messages="errors.email"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-switch v-model="form.active" inset :label="`Is Active: ${form.active == 1 ? 'YES' : 'NO'
                                }`"></v-switch>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-5 mb-5"> </v-divider>

                    <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
                    <v-btn v-else color="primary" dark @click="save"> Update Teacher </v-btn>
                </v-card-text>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
import axios from "axios";
export default {
    name: "TeacherEditPage",
    data() {
        return {
            form: {
                active: true,
                doj: "",
            },
            companies: [],
            dob_menu: false,
            roles: [
                {
                    id: 0,
                    text: "Select Role",
                    value: "",
                },
            ],
            ranks: [],
            courses: [],
            gender: ['Male', 'Female'],
            duration: [
                '7 Days',
                '14 Days',
                '24 Days',
                '28 Days',
            ],
            image_path: "",
            passport_image_path: "",
            cdc_image_path: "",
            sid_image_path: "",
            coc_image_path: "",
            watchkeeping_image_path: "",
            cop_image_path: "",
            user: "",
            courserow: [],
            rows: [], // Array to store dynamically added rows
            isLoading: false,
        };
    },

    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.isLoading = true;
            let form = await axios.get(`/users/${this.$route.params.teacherId}`);
            this.form = form.data.data;
            console.log(this.form);
            this.form.roles.length > 0
                ? (this.form.role_id = this.form.roles[0].id)
                : (this.form.role_id = "");
            this.isLoading = false;
        },
        async save() {
            try {
                this.isLoading = true;
                await axios.patch(`/users/${this.$route.params.teacherId}`, this.form);
                this.isLoading = false;
                this.$router.push("/teacher");
            } catch (e) {
                this.isLoading = false;
            }
        }
    }
}
</script>