<template>
    <v-main>
        <v-container fluid>
            <v-card elevation="6">
                <v-card-title class="mt-4">
                    <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
                        <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add a Org
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field v-model="form.name" outlined label="Org Name *" prepend-inner-icon="mdi-buffet"
                                :error-messages="errors.name"></v-text-field>
                        </v-col>

                    </v-row>
                    <v-divider class="mt-5 mb-5"> </v-divider>

                    <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
                    <v-btn v-else color="primary" dark @click="save"> Save Org </v-btn>
                </v-card-text>
            </v-card>
        </v-container>
    </v-main>
</template>
<script>
import axios from "axios";
export default {
    name: "OrgCreate",
    data() {
        return {
            form: {

            },
            companyId: "",
            doj_menu: false,
            roles: [
                {
                    id: 0,
                    text: "Select Role",
                    value: "",
                },
            ],
            supervisors: [
                {
                    id: 0,
                    text: "Select Supervisors",
                    value: "",
                },
            ],
            designations: [
                {
                    id: 0,
                    text: "Select Designation",
                    value: "",
                },
            ],
            bloodgroups: [{ id: 0, text: "Select Blood Group", value: "" }],
            isLoading: false,
        };
    },

    methods: {

        async save() {
            try {
                this.isLoading = true;
                await axios.post("/orgs", this.form);
                this.isLoading = false;
                this.$router.push("/orgs");
            } catch (e) {
                // alert("error");
                this.isLoading = false;
            }
        },
    },
};
</script>
  