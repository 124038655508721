<template>
  <!-- partial -->
  <v-main>
    <v-row style="padding: 20px;">
      <div class="page-content">
        <div class="container-fluid">
          <div class="row heading-bg">

            <div class="col-md-12 grid-margin stretch-card" style="padding-top: 20px;">
              <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 class="txt-dark">DashBoard</h5>
              </div>
              <SaCounts></SaCounts>
            </div>
            <!-- <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12" v-if="roleId == 2">
          <h5 class="txt-dark">Technician Count (Current day data)</h5>
        </div>   
         <div class="col-md-12 grid-margin stretch-card" v-if="roleId == 2">
          <TaCounts></TaCounts>
        </div>   
         <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Tickets</h5>
        </div>
         <div class="col-md-12 grid-margin stretch-card">
          <TdCounts></TdCounts>
        </div>       -->
          </div>
        </div>
        <div class="chart_data">
          <div id="chart">
          </div>
        </div>
      </div>
    </v-row>
  </v-main>


  <!-- partial -->
</template>
<script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>
<script>
import SaCounts from "./dashboardcount.vue";
// import TaCounts from "./techniciancount.vue";
// import TdCounts from "./ticketdetailed.vue";
import ApexCharts from 'apexcharts'
import axios from "axios";

//   import SaStatus from "./contain.vue";

export default {
  name: "HomePage",
  data() {
    return {
      isLoading: false,
      roleId: "",
      counts:{}
    };
  },


  components: {
    SaCounts,
  },
  async mounted() {
    this.roleId = this.user.roles[0].id;
    // const ctx = document.getElementById('myChart');
     await this.getData();
    console.log(this.counts.totalUser)
      console.log('values')
      var options = {
  chart: {
    type: 'bar'
  },
  series: [{
            data: [this.counts.totalUser, this.counts.totalTeacher, this.counts.totalCourse, this.counts.totalCourseCompletedCandidate, this.counts.totaluser_present_today, this.counts.totaluser_absent_today, this.counts.totaluser_onleave_today]
          }],
          
  xaxis: {
    categories: [
                ['Total', 'Candidates'],
                ['Total', 'Teachers'],
                ['Total', 'Course'],
                ['Total', 'Completed'],
                // ['Today', 'Present'],
                // ['Today', 'Absent'],
                // ['on-Leave', 'Today'], 
              ],
  }
}

var chart = new ApexCharts(document.querySelector("#chart"), options);

chart.render();
// new Chart(ctx, {
//   type: 'bar',
//   data: {
//     labels: ['Total Users', 'Total supversior', 'approved leave', 'Decline Leave', 'Present Today', 'Absent Today','On leave today'],
//     datasets: [{
//       label: '# of Votes',
//       data: [this.counts.totalUser, this.counts.total_supervisor, this.counts.totaluser_leave_approved, this.counts.totaluser_leave_decline, this.counts.totaluser_present_today, this.counts.totaluser_absent_today,this.counts.totaluser_onleave_today],
//       borderWidth: 1,
//       // console.log(this.counts.totalUser)
//       // console.log('values')

//     }]
//   },
//   options: {
//     scales: {
//       y: {
//         beginAtZero: true
//       }
//     }
//   }
// });
  },

  methods: {
    async getData() {
      let response = await axios.get(`/admin_dashboard`);
      console.log(response);
      this.counts = response.data;
      console.log(this.counts.totalUser);

    },
  },
};
</script>
<style>
#chart {
  width: 650px;
  margin: 35px auto;
  display: flex;
  justify-content: start;
  float: left;
}
</style>
