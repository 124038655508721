<template>
  <div>
    <router-view />
  </div>
</template>
  
  <script type="text/javascript">
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: true,
    };
  },
  components: {},
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
    },
  },
};
</script>
  